import {
  Timestamp,
  deleteDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { collection, doc } from "firebase/firestore";

import { UserChildrenDTO } from "../../models/children/user-children";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import moment from "moment";

export const getUserChild = createAsyncThunk(
  "/userChild/getUserChild",
  async () => {
    const currentUser = await getAuth().currentUser;

    const userChildren: UserChildrenDTO[] = [];

    if (currentUser) {
      const ref = collection(getFirestore(), "user_children");
      const q = query(ref, where("currentUser.uid", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        userChildren.push({
          docId: doc.id,
          ...doc.data().child,
        });
      });
    }

    return userChildren;
  }
);

export const addUserChild = createAsyncThunk(
  "/userChild/addUserChild",
  async (child: UserChildrenDTO) => {
    const currentUser = await getAuth().currentUser;

    return await setDoc(doc(getFirestore(), "user_children", child.docId), {
      child: {
        ...child,
        dateOfBirth: moment(child.dateOfBirth).format("YYYY/MM/DD"),
      },
      currentUser: {
        uid: currentUser?.uid,
        email: currentUser?.email,
      },
      created: Timestamp.now(),
      lastUpdated: Timestamp.now(),
    }).then(() => {
      return child;
    });
  }
);

export const updateUserChild = createAsyncThunk(
  "/userChild/updateUserChild",
  async (child: UserChildrenDTO) => {
    const currentUser = await getAuth().currentUser;

    return await updateDoc(doc(getFirestore(), "user_children", child.docId), {
      child: {
        ...child,
        dateOfBirth: moment(child.dateOfBirth).format("YYYY/MM/DD"),
      },
      currentUser: {
        uid: currentUser?.uid,
        email: currentUser?.email,
      },
      lastUpdated: Timestamp.now(),
    }).then(() => {
      return child;
    });
  }
);

export const removeUserChild = createAsyncThunk(
  "/userChild/removeUserChild",
  async (docId: string) => {
    await deleteDoc(doc(getFirestore(), "user_children", docId));
  }
);
