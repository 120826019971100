import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import ArticleIcon from '@mui/icons-material/Article';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableDTO } from '../../models/table/table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { WidgetCard } from '../shared/Cards/WidgetCard';

interface DataTableProps {
	rows: TableDTO[];
	title: string;
}

export const DataTable: FC<DataTableProps> = ({ rows, title }) => {
	const getFile = async (link: string) => {
		const storage = getStorage();
		const storageRef = ref(storage, link);

		const fileLink = await getDownloadURL(storageRef).then((url) => url);

		window.open(fileLink, '_blank')?.focus();
	};

	return (
		<Box sx={{ mb: 2 }}>
			<WidgetCard
				title={title}
				icon={<ArticleIcon />}
				primaryColor={'#FFAA72'}
				secondaryColor={'#F7E1BA'}
				bgcolor={'#fefffe'}
				content={
					<TableContainer component={Paper} sx={{ p: 2 }}>
						<Table aria-label="caption table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ fontWeight: 700 }}>Field Name</TableCell>
									<TableCell sx={{ fontWeight: 700 }}>Field Value</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, index) => (
									<TableRow key={index}>
										<TableCell component="th" scope="row">
											{row.fieldName}
										</TableCell>
										{row.fieldType === 'text' ? (
											<TableCell>{row.fieldValue}</TableCell>
										) : (
											<TableCell>
												<Button
													variant="contained"
													onClick={() => getFile(row.fieldValue)}
												>
													Wys
												</Button>
											</TableCell>
										)}
									</TableRow>
								))}
								{rows.length === 0 ? (
									<TableRow>
										<TableCell component="th" scope="row" colSpan={2}>
											NO DATA
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				}
			/>
		</Box>
	);
};
