import React, { FC, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';

import AppBar from '@mui/material/AppBar';
import { ReactComponent as BambiniLogo } from '../../icons/bambini_svg/logo-compact.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { adminUserActions } from '../../store/admin-user/admin-user-slice';
import { currentUserActions } from '../../store/current-user/current-user-slice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { userChildActions } from '../../store/user-children/user-children-slice';
import { userDoctorActions } from '../../store/user-doctor/user-doctor-slice';
import { userDriverActions } from '../../store/user-drivers/user-drivers-slice';
import { userFormsActions } from '../../store/user-forms/user-forms-slice';
import { userGuardianActions } from '../../store/user-guardians/user-guardian-slice';
import { getUserForms } from '../../store/user-forms/user-forms-actions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getChildrenData } from '../../store/children-data/children-data-actions';

interface NavBarProps {
	userStatus: string;
}

const NavBar: FC<NavBarProps> = ({ userStatus }) => {
	const dispatch = useAppDispatch();
	const auth = getAuth();
	const navigate = useNavigate();

	const admin = useAppSelector((store) => store.adminUser.data.admin);

	const logout = () => {
		dispatch(currentUserActions.logout());
		dispatch(adminUserActions.logout());
		dispatch(userChildActions.logout());
		dispatch(userGuardianActions.logout());
		dispatch(userDriverActions.logout());
		dispatch(userDoctorActions.logout());
		dispatch(userFormsActions.logout());
	};

	useEffect(() => {
		if (!!auth.currentUser) {
			dispatch(getUserForms());
			const uid = auth.currentUser.uid;

			const adminRef = doc(getFirestore(), 'admins', uid);
			getDoc(adminRef).then((doc) => {
				if (doc.exists() && doc.data()) {
					dispatch(adminUserActions.setAdminUser({ admin: doc.data().admin }));
				}
			});
		}
	}, [dispatch, auth]);

	useEffect(() => {
		if (admin) {
			dispatch(getChildrenData());
		}
	}, [admin]);

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null,
	);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar position="fixed" sx={{ bgcolor: '#68ACE0' }}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{
							mr: 2,
							display: {
								xs: 'none',
								md: 'flex',
								width: '240px',
								height: '50px',
							},
						}}
					>
						<BambiniLogo />
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							<MenuItem
								onClick={() => {
									handleCloseNavMenu();
									navigate('/');
								}}
							>
								<Typography textAlign="center">Tuisblad</Typography>
							</MenuItem>
							{userStatus === 'logged-in' ? (
								<MenuItem
									onClick={() => {
										handleCloseNavMenu();
										navigate('/paneelbord');
									}}
								>
									<Typography textAlign="center">Paneelbord</Typography>
								</MenuItem>
							) : (
								<MenuItem
									onClick={() => {
										handleCloseNavMenu();
										navigate('/teken-in');
									}}
								>
									<Typography textAlign="center">Teken In</Typography>
								</MenuItem>
							)}
							{userStatus === 'logged-in' ? (
								<MenuItem
									onClick={() => {
										handleCloseNavMenu();
										signOut(auth);
										logout();
										navigate('/');
									}}
								>
									<Typography textAlign="center">Teken Uit</Typography>
								</MenuItem>
							) : (
								<MenuItem
									onClick={() => {
										handleCloseNavMenu();
										navigate('/registeer');
									}}
								>
									<Typography textAlign="center">Registreer</Typography>
								</MenuItem>
							)}
						</Menu>
					</Box>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{
							flexGrow: 1,
							display: {
								xs: 'flex',
								md: 'none',
								width: '240px',
								height: '50px',
							},
						}}
					>
						<BambiniLogo />
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<Button
							onClick={() => {
								handleCloseNavMenu();
								navigate('/');
							}}
							sx={{ my: 2, color: 'white', display: 'block' }}
						>
							Tuisblad
						</Button>
						{userStatus === 'logged-in' ? (
							<>
								<Button
									onClick={() => {
										handleCloseNavMenu();
										navigate('/paneelbord');
									}}
									sx={{ my: 2, color: 'white', display: 'block' }}
								>
									Paneelbord
								</Button>
								<Button
									sx={{ my: 2, color: 'white', display: 'block' }}
									onClick={() => {
										signOut(auth);
										logout();
										navigate('/');
									}}
								>
									Teken Uit
								</Button>
							</>
						) : (
							<>
								<Button
									sx={{ my: 2, color: 'white', display: 'block' }}
									onClick={() => navigate('/teken-in')}
								>
									Teken In
								</Button>
								<Button
									sx={{ my: 2, color: 'white', display: 'block' }}
									onClick={() => navigate('/registeer')}
								>
									Registreer
								</Button>
							</>
						)}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default NavBar;
