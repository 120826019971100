import {
  addUserDriver,
  getUserDrivers,
  removeUserDriver,
} from "./user-drivers-actions";

import { DataStatus } from "../../models/data/data-status";
import { UserDriverDTO } from "../../models/driver/user-drivers";
/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

interface DriverStoreDTO {
  data: UserDriverDTO[];
  dataStatus: DataStatus;
}

const initial: DriverStoreDTO = {
  data: [],
  dataStatus: DataStatus.INITIAL,
};

const userDriverSlice = createSlice({
  name: "user-drivers",
  initialState: initial,
  reducers: {
    logout(state: any) {
      state.data = [];
      state.dataStatus = DataStatus.INITIAL;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDrivers.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(getUserDrivers.fulfilled, (state, actions) => {
      state.data = actions.payload;
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(getUserDrivers.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(addUserDriver.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(addUserDriver.fulfilled, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(addUserDriver.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(removeUserDriver.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(removeUserDriver.fulfilled, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(removeUserDriver.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const userDriverActions = userDriverSlice.actions;

export default userDriverSlice;
