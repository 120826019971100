import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/hooks';

import { ChildTableDisplayWidget } from '../../components/Widgets/TableDisplayWidgets/ChildTableDisplayWidget';
import { DataStatus } from '../../models/data/data-status';
import { DoctorTableDisplayWidget } from '../../components/Widgets/TableDisplayWidgets/DoctorTableDisplayWidget';
import { DriverTableDisplayWidget } from '../../components/Widgets/TableDisplayWidgets/DriverTableDisplayWidget';
import { GuardianTableDisplayWidget } from '../../components/Widgets/TableDisplayWidgets/GuardianTableDisplayWidget';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import { UserFormsDataDTO } from '../../models/user-forms/user-forms-data';
import { UserFormsChildDataDTO } from '../../models/user-forms/user-forms-child-data';

export const ManageFamilyPage = () => {
	const data = useAppSelector((store) => store.userForms.data);
	const userFormsStatus = useAppSelector((store) => store.userForms.dataStatus);

	const [children, setChildren] = useState<UserFormsChildDataDTO[]>([]);
	const [guardians, setGuardians] = useState<UserFormsDataDTO[]>([]);
	const [drivers, setDrivers] = useState<UserFormsDataDTO[]>([]);
	const [doctors, setDoctors] = useState<UserFormsDataDTO[]>([]);

	useEffect(() => {
		console.log(data);
		if (data) {
			setChildren(data.children);
			setGuardians(data.guardians);
			setDrivers(data.drivers);
			setDoctors(data.doctor);
		}
	}, [data]);

	return (
		<Box sx={{ mt: 12, width: '100vw' }}>
			{userFormsStatus === DataStatus.LOADING ? (
				<LoadingPage />
			) : (
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					sx={{ px: 2, pb: 2 }}
				>
					<Grid item xs={12} md={6}>
						<ChildTableDisplayWidget userFormsData={children} />
					</Grid>
					<Grid item xs={12} md={6}>
						<GuardianTableDisplayWidget userFormsData={guardians} />
					</Grid>
					<Grid item xs={12} md={6}>
						<DriverTableDisplayWidget userFormsData={drivers} />
					</Grid>
					<Grid item xs={12} md={6}>
						<DoctorTableDisplayWidget userFormsData={doctors} />
					</Grid>
				</Grid>
			)}
		</Box>
	);
};
