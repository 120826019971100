import {
	Box,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	ListItem,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { WidgetCard } from '../../shared/Cards/WidgetCard';
import { useNavigate } from 'react-router-dom';
import { UserFormsDTO } from '../../../models/user-forms/user-forms';
import { reRegisterChildren } from './re-register-helper';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { DataStatus } from '../../../models/data/data-status';
import LoadingPage from '../../shared/Loaders/LoadingPage';
import { RegisterYearsDTO } from '../../../models/register-years/register-years';
import {
	createChildrenData,
	getRegistrationYears,
} from '../../../store/registration-years/registration-years-actions';
import { UserFormsChildDataDTO } from '../../../models/user-forms/user-forms-child-data';
import { YellowButton } from '../../Buttons/CustomButtons';
import { formNeedsReviewCheck } from '../../../pages/DashboardPage/form-need-review-helper';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface SelectChildrenRegisterWidgetProps {
	userForm: UserFormsDTO;
}

export const SelectChildrenRegisterWidget: FC<
	SelectChildrenRegisterWidgetProps
> = ({ userForm }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const data = useAppSelector((store) => store.registrationYears.data);
	const userFormsStatus = useAppSelector(
		(store) => store.registrationYears.dataStatus,
	);

	const [children, setChildren] = useState<UserFormsChildDataDTO[]>([]);
	const [years, setYears] = useState<RegisterYearsDTO[]>([]);
	const [selectedYear, setSelectedYear] = useState<string>('');
	const [selectedRegistrationYear, setSelectedRegistrationYear] = useState<
		RegisterYearsDTO | undefined
	>({
		docId: '',
		active: true,
		deposit: 'Loading',
		registerFee: 'Loading',
		schoolFee: 'Loading',
		afterSchool: 'Loading',
	});

	useEffect(() => {
		dispatch(getRegistrationYears());
	}, [dispatch]);

	useEffect(() => {
		if (data) {
			setYears(data);

			if (data[0] != null) {
				setSelectedYear(data[0].docId);
				setSelectedRegistrationYear(data[0]);
			}
		}
	}, [data]);

	const submitForm = async () => {
		const newChildren = await reRegisterChildren(selectedYear, children);

		dispatch(createChildrenData(selectedYear));

		if (newChildren != null) {
			dispatch(userFormsActions.updateChildren(newChildren));
		}

		return false;
	};

	const handleChange = (event: SelectChangeEvent) => {
		setSelectedYear(event.target.value as string);
		setSelectedRegistrationYear(
			data.find((year) => year.docId === event.target.value),
		);
	};

	const addChild = (child: UserFormsChildDataDTO) => {
		const newChildren = [...children, child];
		setChildren(newChildren);
	};

	const removeChild = (child: UserFormsChildDataDTO) => {
		const newChildren = children.filter((c) => c.docId !== child.docId);
		setChildren(newChildren);
	};

	const alreadyRegistered = (child: UserFormsChildDataDTO) => {
		return child.registrationYears.includes(selectedYear);
	};

	return (
		<Box sx={{ maxWidth: '800px', width: '95%', m: 'auto' }}>
			{userFormsStatus === DataStatus.LOADING ? (
				<LoadingPage />
			) : (
				<WidgetCard
					title={'Registreer Kinders'}
					icon={<ChildFriendlyIcon />}
					primaryColor={'#FFAA72'}
					secondaryColor={'#F7E1BA'}
					bgcolor={'#fefffe'}
					content={
						<React.Fragment>
							<FormGroup sx={{ m: '16px' }}>
								<FormControl fullWidth>
									<InputLabel>Registreer Jaar</InputLabel>
									<Select
										value={selectedYear}
										label="Registrasie Jaar"
										onChange={handleChange}
									>
										{years.map((year, index) => {
											return (
												<MenuItem key={index} value={year.docId}>
													{year.docId}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								{selectedYear.length > 0 ? (
									<>
										<List sx={{ width: '100%', bgcolor: '#fefffe' }}>
											<ListItem>
												<ListItemText
													sx={{ my: 0 }}
													primary="Skoolgeld Inligting"
													primaryTypographyProps={{
														fontSize: 20,
														fontWeight: 600,
														letterSpacing: 0,
													}}
												/>
											</ListItem>
											<Divider />
											<ListItem>
												<ListItemText
													primary={
														'Skoolgeld: ' + selectedRegistrationYear?.schoolFee
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														'Naskool indien nodig: ' +
														selectedRegistrationYear?.afterSchool
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														'Deposito: ' + selectedRegistrationYear?.deposit
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														'Registrasie Fooi ' +
														selectedRegistrationYear?.registerFee
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													sx={{ my: 0 }}
													primary="Kies Kinders"
													primaryTypographyProps={{
														fontSize: 20,
														fontWeight: 600,
														letterSpacing: 0,
													}}
												/>
											</ListItem>
											<Divider />
											{userForm.children.map((userFormData, index) => {
												return (
													<React.Fragment key={index}>
														<ListItem>
															<ListItemText>
																<FormControlLabel
																	control={
																		<Checkbox
																			onChange={(e) => {
																				if (e.target.checked) {
																					addChild(userFormData);
																				} else {
																					removeChild(userFormData);
																				}
																			}}
																			disabled={alreadyRegistered(userFormData)}
																			sx={{
																				color: '#F7E1BA',
																				'&.Mui-checked': {
																					color: '#FFAA72',
																				},
																			}}
																		/>
																	}
																	label={
																		userFormData.preferredName +
																		' ' +
																		userFormData.surname
																	}
																/>
															</ListItemText>
															{alreadyRegistered(userFormData) ? (
																<Chip label="Reeds Geregistreer" size="small" />
															) : (
																<></>
															)}
														</ListItem>
													</React.Fragment>
												);
											})}
										</List>
										<Grid
											container
											direction="row"
											justifyContent="space-between"
											alignItems="stretch"
											spacing={2}
										>
											<Grid item xs={6}>
												<YellowButton
													variant="contained"
													fullWidth
													startIcon={<AddBoxIcon />}
													disabled={children.length === 0}
													onClick={() => {
														submitForm();
														navigate(formNeedsReviewCheck(userForm));
													}}
												>
													Registreer Kinders
												</YellowButton>
											</Grid>
											<Grid item xs={6}>
												<YellowButton
													fullWidth
													variant="contained"
													startIcon={<AddBoxIcon />}
													onClick={() => {
														navigate('/kind/nuwe-kind');
														submitForm();
													}}
												>
													Registreer Nuwe Kind
												</YellowButton>
											</Grid>
										</Grid>
									</>
								) : (
									<></>
								)}
							</FormGroup>
						</React.Fragment>
					}
				/>
			)}
		</Box>
	);
};
