import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

interface InputSelectFieldProps {
	inputSelectField: InputSelectFieldDTO;
}

const InputSelectField: FC<InputSelectFieldProps> = ({ inputSelectField }) => {
	const name = inputSelectField.name;
	const list = inputSelectField.list;
	const required = inputSelectField.required;
	const error = inputSelectField.error;
	const setError = inputSelectField.setError;
	const value = inputSelectField.value;
	const setValue = inputSelectField.setValue;

	const [initial, setInitial] = useState(true);
	const [helperText, setHelperText] = useState('');

	useEffect(() => {
		if (value && value !== '') {
			validateInput(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateInput = (newValue: string | null) => {
		setInitial(false);
		setValue(newValue);

		if (newValue) {
			setError(false);
			setHelperText('');
		} else {
			setError(true);
			setHelperText("Kies 'n geldige opsie");
		}
	};

	return (
		<FormControl
			required={required}
			error={error && !initial}
			fullWidth
			sx={{ height: 70 }}
		>
			<InputLabel>{name}</InputLabel>
			<Select
				value={value}
				label={name + (required ? ' *' : '')}
				onChange={(event) => validateInput(event.target.value)}
			>
				{list.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default InputSelectField;
