import React, { FC } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import BambiniLogo from '../../icons/bambini_svg/logo.svg';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalStyles } from '@mui/material';
import Typography from '@mui/material/Typography';

const theme = createTheme({
	typography: {
		h5: {
			fontWeight: 700,
		},
	},
});

interface AuthCardProps {
	title: string;
	body: JSX.Element;
}

const AuthCard: FC<AuthCardProps> = ({ title, body }) => {
	return (
		<ThemeProvider theme={theme}>
			<Container
				component="main"
				sx={{
					bgcolor: '#FAF9F6',
					px: 4,
					pb: 2,
					borderRadius: 3,
					boxShadow: 5,
					mt: 12.5,
					width: 450,
					maxWidth: '95%',
				}}
			>
				<CssBaseline />
				<GlobalStyles
					styles={{
						body: { backgroundColor: '#A5D5F9' },
					}}
				/>
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						pt: 4,
						bgcolor: '#FAF9F6',
					}}
				>
					<Box sx={{ height: 150, width: 200 }}>
						<img src={BambiniLogo} alt="img"></img>
					</Box>
				</Box>
				<Box>
					<Typography component="h1" variant="h5">
						{title}
					</Typography>
					{body}
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default AuthCard;
