import { Box, Grid, Typography } from '@mui/material';

import { PurpleButton } from '../../Buttons/CustomButtons';
import React from 'react';
import { WidgetInfoCard } from '../../shared/Cards/WidgetInfoCard';
import scienceGirlPurple from '../../../icons/bambini_svg/science_girl_purple.svg';
import { useNavigate } from 'react-router-dom';

export const AdminWidget = () => {
	const navigate = useNavigate();
	return (
		<WidgetInfoCard
			bgcolor={'#C7C7F2'}
			content={
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					sx={{ p: 2 }}
				>
					<Grid item xs={12} md={6}>
						<Typography
							variant="overline"
							sx={{
								fontWeight: 700,
								fontSize: 20,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							gutterBottom
						>
							Administrateur
						</Typography>
						<Typography variant="body1" gutterBottom>
							Wys inskrywings vorms. Naamlik Kinder, Ouer, Drywer en Mediese
							Indligting
						</Typography>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<PurpleButton
								variant="contained"
								color="secondary"
								sx={{ mt: 2 }}
								onClick={() => {
									navigate('/administrateur');
								}}
							>
								Administrateur Nou
							</PurpleButton>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box sx={{ maxHeight: 300, maxWidth: 300, m: 'auto' }}>
							<img src={scienceGirlPurple} alt="img"></img>
						</Box>
					</Grid>
				</Grid>
			}
		/>
	);
};
