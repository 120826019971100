import { Box, GlobalStyles, Grid } from '@mui/material';

import Background_4 from '../../../icons/bambini_svg/background-4.svg';
import React from 'react';

const NotFoundPage = () => {
	return (
		<Box>
			<GlobalStyles
				styles={{
					body: { backgroundColor: '#FFFFFF' },
				}}
			/>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={3}>
					<Box sx={{ width: '50vw', minWidth: 400 }}>
						<img src={Background_4} alt="img" />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default NotFoundPage;
