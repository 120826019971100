import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';

import { DataStatus } from '../../models/data/data-status';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import { getRegistrationYears } from '../../store/registration-years/registration-years-actions';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from '@mui/x-data-grid';

export const AdminDashboardSummaryTable = () => {
  const dispatch = useAppDispatch();

  const childrenData = useAppSelector((store) => store.childrenData.data);
  const childrenStatus = useAppSelector(
    (store) => store.childrenData.dataStatus,
  );

  useEffect(() => {
    dispatch(getRegistrationYears());
  }, [dispatch]);

  const rows: GridRowsProp = childrenData.children.map((child, index) => {
    let guardianOneName = 'NA';
    let guardianOnePreferredName = 'NA';
    let guardianOneSurname = 'NA';
    let guardianOneCellNumber = 'NA';
    let guardianOneEmail = 'NA';

    if (!!child.guardians[0]) {
      guardianOneName = child.guardians[0].name;
      guardianOnePreferredName = child.guardians[0].preferredName;
      guardianOneSurname = child.guardians[0].surname;
      guardianOneCellNumber = child.guardians[0].cellNumber;
      guardianOneEmail = child.guardians[0].email;
    }

    let guardianTwoName = 'NA';
    let guardianTwoPreferredName = 'NA';
    let guardianTwoSurname = 'NA';
    let guardianTwoCellNumber = 'NA';
    let guardianTwoEmail = 'NA';

    if (!!child.guardians[1]) {
      guardianTwoName = child.guardians[1].name;
      guardianTwoPreferredName = child.guardians[1].preferredName;
      guardianTwoSurname = child.guardians[1].surname;
      guardianTwoCellNumber = child.guardians[1].cellNumber;
      guardianTwoEmail = child.guardians[1].email;
    }

    let doctorName = 'NA';
    let doctorSurname = 'NA';
    let doctorCellNumber = 'NA';

    if (!!child.doctors[0]) {
      doctorName = child.doctors[0].name;
      doctorSurname = child.doctors[0].surname;
      doctorCellNumber = child.doctors[0].cellNumber;
    }

    return {
      id: index,
      name: child.name,
      surname: child.surname,
      dateOfBirth: child.dateOfBirth,
      registrationYear: child.registrationYear,
      guardianOneName,
      guardianOnePreferredName,
      guardianOneSurname,
      guardianOneCellNumber,
      guardianOneEmail,
      guardianTwoName,
      guardianTwoPreferredName,
      guardianTwoSurname,
      guardianTwoCellNumber,
      guardianTwoEmail,
      doctorName,
      doctorSurname,
      doctorCellNumber,
    };
  });

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Child Name', width: 150 },
    { field: 'surname', headerName: 'Child Surname', width: 150 },
    { field: 'dateOfBirth', headerName: 'Date of Birth', width: 150 },
    { field: 'registrationYear', headerName: 'Registration Year', width: 150 },
    {
      field: 'guardianOnePreferredName',
      headerName: 'guardianOnePreferredName',
      width: 150,
    },
    {
      field: 'guardianOneSurname',
      headerName: 'guardianOneSurname',
      width: 150,
    },
    {
      field: 'guardianOneCellNumber',
      headerName: 'guardianOneCellNumber',
      width: 150,
    },
    { field: 'guardianOneEmail', headerName: 'guardianOneEmail', width: 150 },
    {
      field: 'guardianTwoPreferredName',
      headerName: 'guardianTwoPreferredName',
      width: 150,
    },
    {
      field: 'guardianTwoSurname',
      headerName: 'guardianTwoSurname',
      width: 150,
    },
    {
      field: 'guardianTwoCellNumber',
      headerName: 'guardianTwoCellNumber',
      width: 150,
    },
    { field: 'guardianTwoEmail', headerName: 'guardianTwoEmail', width: 150 },
    { field: 'doctorName', headerName: 'doctorName', width: 150 },
    { field: 'doctorSurname', headerName: 'doctorSurname', width: 150 },
    { field: 'doctorCellNumber', headerName: 'doctorCellNumber', width: 150 },
  ];

  return (
    <Box sx={{ mt: 12, width: '100vw' }}>
      {childrenStatus === DataStatus.LOADING ? (
        <LoadingPage />
      ) : (
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={2}
          sx={{ px: 2, pb: 2 }}
        >
          <Grid item xs={12} style={{ height: '85vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
