import {
	GenerateInputNumberField,
	GenerateInputTextField,
} from '../FormBuilder/FormBuilderService';
import React, { FC, useState } from 'react';

import FormBuilder from '../FormBuilder/FormBuilder';
import FormContainer from '../FormBuilder/FormContainer';
import { FormContentDTO } from '../../../models/forms/form-content';
import { FormFieldData } from '../../../models/forms/form-field-data';
import { FormFieldTypes } from '../../../models/forms/form-field-types';
import { UserDoctorDTO } from '../../../models/doctor/user-doctor';
import { addUserDoctor } from '../../../store/user-doctor/user-doctor-actions';
import { v4 as uid } from 'uuid';
import { useAppDispatch } from '../../../hooks/hooks';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface DoctorFormGeneratorProps {
	currentDoctor: UserDoctorDTO | null;
}

export const DoctorFormGenerator: FC<DoctorFormGeneratorProps> = ({
	currentDoctor,
}) => {
	const dispatch = useAppDispatch();
	const [exists] = useState(currentDoctor ? true : false);
	const [docId] = useState(
		currentDoctor !== null ? currentDoctor.docId : uid(),
	);

	const name: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Volle Naam',
			currentDoctor != null ? currentDoctor.name : '',
			true,
		),
	};

	const surname: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Van',
			currentDoctor?.surname ? currentDoctor.surname : '',
			true,
		),
	};

	const cellNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Selfoonnommer',
			currentDoctor?.cellNumber ? currentDoctor.cellNumber : '',
			true,
			10,
		),
	};

	const medicalSchemeName: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Naam van Mediesefond',
			currentDoctor?.medicalSchemeName ? currentDoctor.medicalSchemeName : '',
			true,
		),
	};

	const medicalSchemeNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Nommer van Mediesefond',
			currentDoctor?.medicalSchemeNumber
				? currentDoctor.medicalSchemeNumber
				: '',
			true,
		),
	};

	const allergies: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_MULTI_LINE_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Allergieë lys (Spesifiseer kind se NAAM en allergeë)',
			currentDoctor?.allergies ? currentDoctor.allergies : '',
			false,
		),
	};

	const conditions: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_MULTI_LINE_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Enige aspek van kind/kinders waarvan ons kennis moet dra (Spesifiseer kind se NAAM en aspek/te)',
			currentDoctor?.conditions ? currentDoctor.conditions : '',
			false,
		),
	};

	const doctorInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Dokter se Inligting',
	};

	const childMedicalInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Kind se Mediese Inligting(Optioneel)',
	};

	const submit = (validForm: boolean) => {
		const doctor: UserDoctorDTO = {
			docId,
			name: name.formFieldData.value,
			surname: surname.formFieldData.value,
			cellNumber: cellNumber.formFieldData.value,
			medicalSchemeName: medicalSchemeName.formFieldData.value,
			medicalSchemeNumber: medicalSchemeNumber.formFieldData.value,
			allergies: allergies.formFieldData.value,
			conditions: conditions.formFieldData.value,
			validForm,
		};
		dispatch(addUserDoctor(doctor));
		if (exists) {
			dispatch(
				userFormsActions.updateDoctor({
					docId,
					preferredName: name.formFieldData.value,
					surname: surname.formFieldData.value,
				}),
			);
		} else {
			dispatch(
				userFormsActions.addDoctor({
					docId,
					preferredName: name.formFieldData.value,
					surname: surname.formFieldData.value,
				}),
			);
		}
	};

	const form: FormContentDTO = {
		formContent: [
			doctorInfo,
			name,
			surname,
			cellNumber,
			medicalSchemeName,
			medicalSchemeNumber,
			childMedicalInfo,
			allergies,
			conditions,
		],
	};

	return <FormContainer body={<FormBuilder form={form} submit={submit} exists={exists}/>} />;
};
