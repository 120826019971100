import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import { DataStatus } from '../../models/data/data-status';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import { adminUserActions } from '../../store/admin-user/admin-user-slice';
import { doc } from 'firebase/firestore';

import { getRegistrationYears } from '../../store/registration-years/registration-years-actions';
import { RegisterYearsDTO } from '../../models/register-years/register-years';
import { ShowRegisteredChildrenWidget } from '../../components/Widgets/AdminDashboardWidgets/ShowRegistrationYearCountWidget';
import { ShowSummaryDataViewWidget } from '../../components/Widgets/AdminDashboardWidgets/ShowSummaryDataViewWidget';

const AdminCheck = (uid: string) => {
	const dispatch = useAppDispatch();
	const adminRef = doc(useFirestore(), 'admins', uid);
	const { status, data } = useFirestoreDocData(adminRef);

	if (status === 'success') {
		if (data) {
			dispatch(adminUserActions.setAdminUser({ admin: data.admin }));
		}
	}
};

export const AdminDashboardPage = () => {
	const dispatch = useAppDispatch();

	const data = useAppSelector((store) => store.registrationYears.data);
	const userFormsStatus = useAppSelector((store) => store.registrationYears.dataStatus);

	const [years, setYears] = useState<RegisterYearsDTO[]>([]);

	const uid = useAppSelector((store) => store.currentUser.data.uid);
	AdminCheck(uid);

	useEffect(() => {
		dispatch(getRegistrationYears());
	}, [dispatch]);

	useEffect(() => {
		if (data) {
			setYears(data);
		}
	}, [data]);

	return (
		<Box sx={{ mt: 12, width: '100vw' }}>
			{userFormsStatus === DataStatus.LOADING ? (
				<LoadingPage />
			) : (
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					sx={{ px: 2, pb: 2 }}
				>

					{years.map((year, index) => {
						return (<Grid key={index} item xs={12} md={6}>
							<ShowRegisteredChildrenWidget year={year.docId} />
						</Grid>);
					})
					}
					<Grid item xs={12} md={6}>
						<ShowRegisteredChildrenWidget year={"Al Die"} />
					</Grid>
					<Grid item xs={12} md={6}>
						<ShowSummaryDataViewWidget />
					</Grid>
				</Grid>
			)}
		</Box>
	);
};
