import React, { FC, useEffect, useState } from 'react';

import { TextField } from '@mui/material';

interface InputFieldProps {
	inputNumberField: InputNumberFieldDTO;
}

const InputField: FC<InputFieldProps> = ({ inputNumberField }) => {
	const name = inputNumberField.name;
	const required = inputNumberField.required;
	const lengthEqual = inputNumberField.lengthEqual;
	const value = inputNumberField.value;
	const setValue = inputNumberField.setValue;
	const error = inputNumberField.error;
	const setError = inputNumberField.setError;

	const maxLength: number = 255;

	const [initial, setInitial] = useState(true);
	const [helperText, setHelperText] = useState('');

	useEffect(() => {
		if (value !== '') {
			validateInput(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const appendToErrorMessage = (message: string, addMessage: string) => {
		if (message === '') {
			return addMessage;
		}

		return message + ', ' + addMessage;
	};

	const validateInput = (newValue: string) => {
		setInitial(false);
		setValue(newValue);
		let isError = false;
		let errorMessage = '';

		const isNumeric = (value: string): boolean =>
			new RegExp(/[^\d.]/g).test(value.trim());

		if (isNumeric(newValue)) {
			isError = true;
			errorMessage = 'Moet nommers wees';
		}

		if (lengthEqual && !(newValue.length === lengthEqual)) {
			isError = true;
			errorMessage = appendToErrorMessage(
				errorMessage,
				'Moet gelyk aan ' + lengthEqual.toString() + ' nommers wees',
			);
		}

		if (maxLength && !(newValue.length <= maxLength)) {
			isError = true;
			errorMessage = appendToErrorMessage(
				errorMessage,
				'Moet korter of gelyk aan ' + maxLength.toString() + ' karakters wees',
			);
		}

		if (!required && newValue.length === 0) {
			isError = false;
			errorMessage = '';
		}

		setHelperText(errorMessage);
		setError(isError);
	};
	return (
		<TextField
			error={error && !initial}
			fullWidth
			required={required}
			name={name}
			label={name}
			type="text"
			autoComplete={name}
			defaultValue={value}
			helperText={helperText}
			sx={{ height: 70 }}
			onChange={(event) => validateInput(event.target.value)}
		/>
	);
};

export default InputField;
