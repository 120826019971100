import {
  Timestamp,
  deleteDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { collection, doc } from "firebase/firestore";

import { UserDriverDTO } from "../../models/driver/user-drivers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";

export const getUserDrivers = createAsyncThunk(
  "/userDrivers/getUserDrivers",
  async () => {
    const currentUser = await getAuth().currentUser;

    const userDrivers: UserDriverDTO[] = [];

    if (currentUser) {
      const ref = collection(getFirestore(), "user_drivers");
      const q = query(ref, where("currentUser.uid", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        userDrivers.push({
          docId: doc.id,
          ...doc.data().drivers,
        });
      });
    }

    return userDrivers;
  }
);

export const addUserDriver = createAsyncThunk(
  "/userDrivers/addUserDriver",
  async (driver: UserDriverDTO) => {
    const currentUser = await getAuth().currentUser;

    await setDoc(doc(getFirestore(), "user_drivers", driver.docId), {
      driver,
      currentUser: {
        uid: currentUser?.uid,
        email: currentUser?.email,
      },
      created: Timestamp.now(),
      lastUpdated: Timestamp.now(),
    });
  }
);

export const removeUserDriver = createAsyncThunk(
  "/userDrivers/removeUserDriver",
  async (docId: string) => {
    await deleteDoc(doc(getFirestore(), "user_drivers", docId));
  }
);
