import { Box, Grid, Typography } from '@mui/material';
import { YellowButton } from '../../Buttons/CustomButtons';

import React from 'react';
import { WidgetInfoCard } from '../../shared/Cards/WidgetInfoCard';
import family from '../../../icons/bambini_svg/family.svg';
import { useNavigate } from 'react-router-dom';

export const ManageFormsWidget = () => {
	const navigate = useNavigate();
	return (
		<WidgetInfoCard
			bgcolor={'#FFCE72'}
			content={
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					sx={{ p: 2 }}
				>
					<Grid item xs={12} md={6}>
						<Typography
							variant="overline"
							sx={{
								fontWeight: 700,
								fontSize: 20,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							gutterBottom
						>
							Bestuur My Familie
						</Typography>
						<Typography variant="body1" gutterBottom>
							Verander kinders, ouers, drywers en mediese inligting.
						</Typography>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<YellowButton
								variant="contained"
								color="secondary"
								sx={{ mt: 2 }}
								onClick={() => {
									navigate('/bestuur-familie');
								}}
							>
								Bestuur Nou
							</YellowButton>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box sx={{ maxHeight: 300, maxWidth: 300, m: 'auto' }}>
							<img src={family} alt="img"></img>
						</Box>
					</Grid>
				</Grid>
			}
		/>
	);
};
