import { IconButton, ListItem, ListItemButton } from '@mui/material';
import React, { FC } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Avatar from '@mui/material/Avatar';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PersonIcon from '@mui/icons-material/Person';
import { UserFormsDataDTO } from '../../../models/user-forms/user-forms-data';
import { WidgetCard } from '../../shared/Cards/WidgetCard';
import { YellowButton } from '../../Buttons/CustomButtons';
import { removeUserDoctor } from '../../../store/user-doctor/user-doctor-actions';
import { useAppDispatch } from '../../../hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface DoctorTableDisplayWidgetProps {
	userFormsData: UserFormsDataDTO[];
}

export const DoctorTableDisplayWidget: FC<DoctorTableDisplayWidgetProps> = ({ userFormsData }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const removeData = (docId: string) => {
		dispatch(userFormsActions.removeUserDoctor(docId));
		dispatch(removeUserDoctor(docId));
	};

	return (
		<WidgetCard
			title={'Dokters'}
			icon={<LocalHospitalIcon />}
			primaryColor={'#FFAA72'}
			secondaryColor={'#F7E1BA'}
			bgcolor={'#fefffe'}
			content={
				<React.Fragment>
					<List sx={{ width: '100%', bgcolor: '#fefffe' }}>
						{userFormsData.length === 0 ? (
							<ListItemButton>
								<ListItemAvatar>
									<Avatar
										variant="rounded"
										sx={{
											color: '#ff1744',
											bgcolor: '#f4f2f8',
										}}
									>
										<DangerousIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									sx={{ font: '#ff1744' }}
									primary={'Geen rekords nie'}
								/>
							</ListItemButton>
						) : (
							userFormsData.map((userFormData, index) => {
								return (
									<React.Fragment key={index}>
										<ListItem>
											<ListItemButton
												onClick={() =>
													navigate('/dokter/' + userFormData.docId)
												}
											>
												<ListItemAvatar>
													<Avatar
														variant="rounded"
														sx={{
															color: '#FFCE72',
															bgcolor: '#f4f2f8',
														}}
													>
														<PersonIcon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={
														userFormData.preferredName +
														' ' +
														userFormData.surname
													}
												/>
												<ArrowForwardIosIcon />
											</ListItemButton>
											<IconButton
												color="error"
												component="span"
												onClick={() => removeData(userFormData.docId)}
											>
												<DeleteIcon />
											</IconButton>
										</ListItem>
									</React.Fragment>
								);
							})
						)}
					</List>
					<YellowButton
						variant="contained"
						sx={{ m: 2 }}
						startIcon={<AddBoxIcon />}
						onClick={() => {
							navigate('/dokter/nuwe-dokter');
						}}
					>
						Dokter
					</YellowButton>
				</React.Fragment>
			}
		/>
	);
};
