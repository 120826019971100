import {
	Avatar,
	Box,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@mui/material';
import React, { FC } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import { FormErrorDTO } from '../../models/form-error/form-error';
import Modal from '@mui/material/Modal';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { WidgetCard } from '../shared/Cards/WidgetCard';
import { YellowButton } from '../Buttons/CustomButtons';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	maxWidth: '95%',
	p: 2,
};

interface ErrorsModalProps {
	errors: FormErrorDTO[];
	open: boolean;
	setOpen: (state: boolean) => void;
}

const ErrorsModal: FC<ErrorsModalProps> = ({ errors, open, setOpen }) => {
	const handleClose = () => setOpen(false);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<WidgetCard
					title={'Vorm onvolledig'}
					icon={<ErrorIcon />}
					primaryColor={'#FFAA72'}
					secondaryColor={'#F7E1BA'}
					bgcolor={'#fefffe'}
					content={
						<>
							<List sx={{ width: '100%', bgcolor: '#fefffe' }}>
								{errors.slice(0, 5).map((error, index) => {
									return (
										<ListItem key={index}>
											<ListItemAvatar>
												<Avatar
													variant="rounded"
													sx={{
														color: '#FFCE72',
														bgcolor: '#f4f2f8',
													}}
												>
													<PriorityHighIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={error.name} />
										</ListItem>
									);
								})}
								{errors.length > 5 ? (
									<ListItem>
										<ListItemAvatar>
											<Avatar
												variant="rounded"
												sx={{
													color: '#FFCE72',
													bgcolor: '#f4f2f8',
												}}
											>
												<MoreHorizIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={'+ ' + (errors.length - 5).toString() + ' foute'}
										/>
									</ListItem>
								) : (
									<></>
								)}
							</List>
							<YellowButton
								variant="contained"
								sx={{ m: 2 }}
								onClick={handleClose}
							>
								Voltooi vorm
							</YellowButton>
						</>
					}
				/>
			</Box>
		</Modal>
	);
};

export default ErrorsModal;
