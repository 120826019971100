import {
	GenerateFileUploadButton,
	GenerateInputNumberField,
	GenerateInputTextField,
} from '../FormBuilder/FormBuilderService';
import React, { FC, useState } from 'react';

import { FileUploadButtonDTO } from '../../../models/forms/file-upload-button';
import FormBuilder from '../FormBuilder/FormBuilder';
import FormContainer from '../FormBuilder/FormContainer';
import { FormContentDTO } from '../../../models/forms/form-content';
import { FormFieldData } from '../../../models/forms/form-field-data';
import { FormFieldTypes } from '../../../models/forms/form-field-types';
import { UserDriverDTO } from '../../../models/driver/user-drivers';
import { addUserDriver } from '../../../store/user-drivers/user-drivers-actions';
import { v4 as uid } from 'uuid';
import { useAppDispatch } from '../../../hooks/hooks';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface DriverFormGeneratorProps {
	currentDriver: UserDriverDTO | null;
}

export const DriverFormGenerator: FC<DriverFormGeneratorProps> = ({
	currentDriver,
}) => {
	const dispatch = useAppDispatch();
	const [exists] = useState(currentDriver ? true : false);
	const [docId] = useState(
		currentDriver !== null ? currentDriver.docId : uid(),
	);

	const name: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Volle Naam',
			currentDriver != null ? currentDriver.name : '',
			true,
		),
	};

	const surname: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Van',
			currentDriver?.surname ? currentDriver.surname : '',
			true,
		),
	};

	const identityNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Identiteitsnommer',
			currentDriver?.identityNumber ? currentDriver.identityNumber : '',
			true,
			13,
		),
	};

	const relationshipWithChild: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Verwantskap met kind',
			currentDriver?.relationshipWithChild
				? currentDriver.relationshipWithChild
				: '',
			true,
		),
	};

	const cellNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Selfoonnommer',
			currentDriver?.cellNumber ? currentDriver.cellNumber : '',
			true,
			10,
		),
	};

	const identityDocumentUrl: FormFieldData<FileUploadButtonDTO> = {
		formFieldType: FormFieldTypes.FILE_UPLOAD_BUTTON,
		formFieldData: GenerateFileUploadButton(
			'Identiteitsdokument',
			currentDriver?.identityDocumentUrl
				? currentDriver.identityDocumentUrl
				: '',
			docId,
			true,
		),
	};

	const driverInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Drywer se Inligting',
	};

	const documentation: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Drywer se Dokumentasie',
	};

	const submit = (validForm: boolean) => {
		const driver: UserDriverDTO = {
			docId,
			name: name.formFieldData.value,
			surname: surname.formFieldData.value,
			relationshipWithChild: relationshipWithChild.formFieldData.value,
			identityNumber: identityNumber.formFieldData.value,
			cellNumber: cellNumber.formFieldData.value,
			identityDocumentUrl: identityDocumentUrl.formFieldData.file,
			validForm,
		};
		dispatch(addUserDriver(driver));
		if (exists) {
			dispatch(
				userFormsActions.updateDriver({
					docId,
					preferredName: name.formFieldData.value,
					surname: surname.formFieldData.value,
				}),
			);
		} else {
			dispatch(
				userFormsActions.addDriver({
					docId,
					preferredName: name.formFieldData.value,
					surname: surname.formFieldData.value,
				}),
			);
		}
	};

	const form: FormContentDTO = {
		formContent: [
			driverInfo,
			name,
			surname,
			relationshipWithChild,
			identityNumber,
			cellNumber,
			documentation,
			identityDocumentUrl,
		],
	};

	return (
		<FormContainer
			body={<FormBuilder form={form} submit={submit} exists={exists} />}
		/>
	);
};
