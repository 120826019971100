import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

interface InputTextIconFieldProps {
	label: string;
	name: string;
	type: string;
	icon: JSX.Element;
	value: string;
	setValue: React.SetStateAction<any>;
	error: boolean;
	setError: React.SetStateAction<any>;
}

const InputTextIconField: FC<InputTextIconFieldProps> = ({
	label,
	name,
	type,
	icon,
	value,
	setValue,
	error,
	setError,
}) => {
	const [initial, setInitial] = useState(true);
	const [helperText, setHelperText] = useState('');

	const validateText = (newValue: string) => {
		setInitial(false);
		let isError = false;
		let errorMessage = '';

		setValue(newValue);
		switch (type) {
			case 'email': {
				const emailRegExp = new RegExp(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				);

				if (!emailRegExp.test(newValue)) {
					isError = true;
					errorMessage = 'E-Pos adres is nie geldig nie';
				}
				break;
			}
			case 'phone': {
				if (newValue.length !== 10) {
					isError = true;
					errorMessage = 'Selfoon nommer is nie geldig nie';
				}
				break;
			}
			case 'text': {
				if (!(newValue.length >= 2 && newValue.length <= 255)) {
					isError = true;
					errorMessage = 'Moet meer as 2 karacters wees';
				}
				break;
			}
			default: {
				break;
			}
		}
		setHelperText(errorMessage);
		setError(isError);
	};
	return (
		<TextField
			margin="normal"
			required
			fullWidth
			name={name}
			label={label}
			type={type}
			id={name}
			defaultValue={value}
			autoComplete={name}
			placeholder={label}
			helperText={helperText}
			error={error && !initial}
			onChange={(e) => validateText(e.target.value)}
			InputProps={{
				startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
			}}
		/>
	);
};

export default InputTextIconField;
