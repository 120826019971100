import { Card, GlobalStyles } from '@mui/material';
import React, { FC } from 'react';

interface WidgetInfoCardProps {
	content: JSX.Element;
	bgcolor: string;
}

export const WidgetInfoCard: FC<WidgetInfoCardProps> = ({
	content,
	bgcolor,
}) => {
	return (
		<Card
			sx={{
				bgcolor: bgcolor,
				borderRadius: 5,
				boxShadow:
					'rgb(145 158 171 / 75%) 0px 0px 1px 0px, rgb(145 158 171 / 50%) 0px 2px 4px -1px',
			}}
		>
			<GlobalStyles
				styles={{
					body: { backgroundColor: '#f8fbfb' },
				}}
			/>
			{content}
		</Card>
	);
};
