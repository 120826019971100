import React, { useEffect, useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { useNavigate, useParams } from 'react-router-dom';

import { DriverFormGenerator } from './DriverFormGenerator';
import LoadingPage from '../../shared/Loaders/LoadingPage';
import { UserDriverDTO } from '../../../models/driver/user-drivers';
import { doc } from 'firebase/firestore';

const DriverForm = () => {
	const navigate = useNavigate();
	const { docId } = useParams();

	const driverRef = doc(useFirestore(), 'user_drivers', docId as string);

	const { status, data } = useFirestoreDocData(driverRef);

	const [form, setForm] = useState<JSX.Element>(<LoadingPage />);

	useEffect(() => {
		if (docId === 'nuwe-drywer') {
			setForm(<DriverFormGenerator currentDriver={null} />);
		} else if (data && status === 'success') {
			setForm(
				<DriverFormGenerator currentDriver={data.driver as UserDriverDTO} />,
			);
		} else if (!data && status === 'success') {
			navigate('/404');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, docId]);

	return form;
};

export default DriverForm;
