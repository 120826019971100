import React, { FC, useEffect, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { InputDateFieldDTO } from '../../../models/forms/form-date-field';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import moment from 'moment';

interface InputFieldProps {
	inputDateField: InputDateFieldDTO;
}

const InputDateField: FC<InputFieldProps> = ({ inputDateField }) => {
	const name = inputDateField.name;
	const required = inputDateField.required;
	const error = inputDateField.error;
	const setError = inputDateField.setError;
	const value = inputDateField.value;
	const setValue = inputDateField.setValue;

	const [initial, setInitial] = useState(true);
	const [helperText, setHelperText] = useState('');

	useEffect(() => {
		if (value) {
			validateInput(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateInput = (newValue: Date | null) => {
		setInitial(false);
		setValue(newValue);
		let isError = false;
		let errorMessage = '';

		if (!moment(newValue).isValid()) {
			isError = true;
			errorMessage = "Kies 'n datum wat bestaan";
		}

		setHelperText(errorMessage);
		setError(isError);
	};
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateTimePicker
				label={name}
				value={value}
				inputFormat="dd/MM/yyyy"
				views={['year', 'month', 'day']}
				onChange={(newValue) => validateInput(newValue)}
				renderInput={(params) => (
					<TextField
						fullWidth
						{...params}
						required={required}
						helperText={helperText}
						sx={{ height: 70 }}
						error={error && !initial}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default InputDateField;
