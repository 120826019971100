import React, { FC, useEffect, useState } from 'react';

import { TextField } from '@mui/material';

interface InputTextFieldProps {
	inputTextField: InputTextFieldDTO;
}

const InputTextField: FC<InputTextFieldProps> = ({ inputTextField }) => {
	const name = inputTextField.name;
	const required = inputTextField.required;
	const type = inputTextField.type;
	const lengthEqual = inputTextField.lengthEqual;
	const value = inputTextField.value;
	const setValue = inputTextField.setValue;
	const error = inputTextField.error;
	const setError = inputTextField.setError;
	const baseHelperText = inputTextField.baseHelperText
		? inputTextField.baseHelperText
		: '';

	const [initial, setInitial] = useState(true);
	const [helperText, setHelperText] = useState('');

	const minLength: number = 2;
	const maxLength: number = 255;

	useEffect(() => {
		if (value !== '') {
			validateInput(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const appendToErrorMessage = (message: string, addMessage: string) => {
		if (message === '') {
			return addMessage;
		}

		return message + ', ' + addMessage;
	};

	const validateInput = (newValue: string) => {
		setInitial(false);
		setValue(newValue);

		let isError = false;
		let errorMessage = '';

		switch (type) {
			case 'email': {
				const emailRegExp = new RegExp(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				);

				if (!emailRegExp.test(newValue)) {
					isError = true;
					errorMessage = 'E-Pos adres is nie geldig nie';
				}
				break;
			}
			case 'phone': {
				if (newValue.length !== 10) {
					isError = true;
					errorMessage = 'Selfoon nommer is nie geldig nie';
				}
				break;
			}
			case 'text': {
				if (minLength && !(newValue.length >= minLength)) {
					isError = true;
					errorMessage = appendToErrorMessage(
						errorMessage,
						'Moet langer of gelyk aan ' +
							minLength.toString() +
							' karakters wees',
					);
				}
				if (maxLength && !(newValue.length <= maxLength)) {
					isError = true;
					errorMessage = appendToErrorMessage(
						errorMessage,
						'Moet korter of gelyk aan ' +
							maxLength.toString() +
							' karakters wees',
					);
				}
				if (lengthEqual && !(newValue.length === lengthEqual)) {
					isError = true;
					errorMessage = appendToErrorMessage(
						errorMessage,
						'Moet gelyk aan ' + lengthEqual.toString() + ' karakters wees',
					);
				}
				break;
			}
			default: {
				break;
			}
		}

		if (!required && newValue.length === 0) {
			isError = false;
			errorMessage = '';
		}

		setHelperText(errorMessage);
		setError(isError);
	};
	return (
		<TextField
			error={error && !initial}
			fullWidth
			required={required}
			label={name}
			name={name}
			type={type}
			defaultValue={value}
			helperText={error && !initial ? helperText : baseHelperText}
			sx={{ height: 70, mb: baseHelperText ? 3 : 0 }}
			onChange={(event) => validateInput(event.target.value)}
		/>
	);
};

export default InputTextField;
