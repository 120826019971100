import { Box } from '@mui/material';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import { AdminChildWidget } from '../../components/Widgets/AdminChildWidget';
import React from 'react';
import { TableLinksDTO } from '../../models/table/tableLinks';
import { collection } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { UserFormsChildDataDTO } from '../../models/user-forms/user-forms-child-data';

function GetAllForms() {
	const userFormsRef = collection(useFirestore(), 'user_forms');

	const { status, data } = useFirestoreCollectionData(userFormsRef);

	if (status === 'loading') {
		return [];
	}

	return data;
}

export const AdminPage = () => {
	const { yearId } = useParams();
	const queryYear: string = yearId ? yearId : '';

	const data: TableLinksDTO[] = [];

	GetAllForms().forEach((form) => {
		const docId = form.NO_ID_FIELD;
		const valid = form.formsComplete;
		form.children.forEach((child: UserFormsChildDataDTO) => {
			if (queryYear === "Al Die" || child.registrationYears.includes(queryYear)) {
				data.push({ child, docId, valid })
			}
		});
	});

	return (
		<Box sx={{ mx: 2, mt: 12 }}>
			<AdminChildWidget userFormsData={data} />
		</Box>
	);
};
