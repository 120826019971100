import { UserFormsDataDTO } from "../../models/user-forms/user-forms-data";
import { UserFormsChildDataDTO } from "../../models/user-forms/user-forms-child-data";
import { UserFormsDTO } from "../../models/user-forms/user-forms";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";

const childrenFormsNeedsReview = (children: UserFormsChildDataDTO[]) => {
  for (const child of children) {
    if (child.archived !== true) {
      if (!!child.needsReview) {
        return child.docId;
      }
    }
  }
  return null;
};

const guardianFormsNeedsReview = (guardians: UserFormsDataDTO[]) => {
  for (const guardian of guardians) {
    if (!!guardian.needsReview) {
      return guardian.docId;
    }
  }
  return null;
};

const driverFormsNeedsReview = (drivers: UserFormsDataDTO[]) => {
  for (const driver of drivers) {
    if (!!driver.needsReview) {
      return driver.docId;
    }
  }
  return null;
};

const doctorFormsNeedsReview = (doctors: UserFormsDataDTO[]) => {
  for (const doctor of doctors) {
    if (!!doctor.needsReview) {
      return doctor.docId;
    }
  }
  return null;
};

export const formNeedsReviewCheck = (userForms: UserFormsDTO): string => {
  if (userForms) {
    const childFormId = childrenFormsNeedsReview(userForms.children);

    if (childFormId !== null) {
      return `/kind/${childFormId}`;
    }

    const guardianFormId = guardianFormsNeedsReview(userForms.guardians);
    if (guardianFormId !== null) {
      return `/ouer/${guardianFormId}`;
    }

    const driverFormId = driverFormsNeedsReview(userForms.drivers);
    if (driverFormId !== null) {
      return `/drywer/${driverFormId}`;
    }

    const doctorFormId = doctorFormsNeedsReview(userForms.doctor);
    if (doctorFormId !== null) {
      return `/dokter/${doctorFormId}`;
    }
  }
  return "/paneelbord";
};

export const createUserForms = async (uid: string) => {
  const userForm: UserFormsDTO = {
    children: [],
    guardians: [],
    drivers: [],
    doctor: [],
    singleParent: null,
    noDrivers: null,
    formsComplete: false,
  };

  const document = await getDoc(doc(getFirestore(), "user_forms", uid));

  if (!document.exists()) {
    await setDoc(doc(getFirestore(), "user_forms", uid), {
      ...userForm,
    });
  }
};
