import { CurrentUserDTO } from "../../models/user/user";
/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

interface CurrentUserStoreDTO {
  data: CurrentUserDTO;
}

const initial: CurrentUserStoreDTO = {
  data: {
    email: "",
    uid: "",
  },
};

const currentUserSlice = createSlice({
  name: "current-user",
  initialState: initial,
  reducers: {
    setCurrentUser(state: any, actions: any) {
      state.data = actions.payload;
    },
    logout(state: any) {
      state.data = [];
    },
  },
});

export const currentUserActions = currentUserSlice.actions;

export default currentUserSlice;
