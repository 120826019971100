import { collection, doc, query, where } from 'firebase/firestore';
import {
	useFirestore,
	useFirestoreCollectionData,
	useFirestoreDocData,
} from 'reactfire';

import { Box } from '@mui/material';
import { DataTable } from '../../components/Tables/DataTable';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import React from 'react';
import { TableDTO } from '../../models/table/table';
import { useParams } from 'react-router-dom';

function GetChildFormData(docId: string): TableDTO[] | null {
	const userChildRef = doc(useFirestore(), 'user_children', docId);

	const { status, data } = useFirestoreDocData(userChildRef);

	if (status === 'loading') {
		return null;
	}

	if (data) {
		return [
			{
				fieldName: 'Volle Naam',
				fieldValue: data.child.name,
				fieldType: 'text',
			},
			{
				fieldName: 'Van',
				fieldValue: data.child.surname,
				fieldType: 'text',
			},
			{
				fieldName: 'Noemnaam',
				fieldValue: data.child.preferredName,
				fieldType: 'text',
			},
			{
				fieldName: 'Geslag',
				fieldValue: data.child.gender,
				fieldType: 'text',
			},
			{
				fieldName: 'Identiteidsnommer',
				fieldValue: data.child.identityNumber,
				fieldType: 'text',
			},
			{
				fieldName: 'Geboortedaturm',
				fieldValue: data.child.dateOfBirth,
				fieldType: 'text',
			},
			{
				fieldName: 'Straat Naam en Nommer',
				fieldValue: data.child.address,
				fieldType: 'text',
			},
			{
				fieldName: 'Area Naam',
				fieldValue: data.child.areaName,
				fieldType: 'text',
			},
			{
				fieldName: 'Area Kode',
				fieldValue: data.child.areaCode,
				fieldType: 'text',
			},
			{
				fieldName: 'Huistaal',
				fieldValue: data.child.homeLanguage,
				fieldType: 'text',
			},
			{
				fieldName: 'Vorige Skool Naam',
				fieldValue: data.child.previousSchool,
				fieldType: 'text',
			},
			{
				fieldName: 'Ek stel belang in nasog',
				fieldValue: data.child.afterSchoolCare,
				fieldType: 'text',
			},
			{
				fieldName: 'Foto Beleid',
				fieldValue: data.child.photosPolicy,
				fieldType: 'text',
			},
			{
				fieldName: 'Immuniteitsertifikaat',
				fieldValue: data.child.immunityCertificateUrl,
				fieldType: 'file',
			},
			{
				fieldName: 'Geboortesertifikaat',
				fieldValue: data.child.dateOfBirthCertificateUrl,
				fieldType: 'file',
			},
		];
	}

	return null;
}

function GetGuardiansFormData(uid: string): TableDTO[] | null {
	const guardians: TableDTO[] = [];
	const userGuardiansRef = collection(useFirestore(), 'user_guardians');
	const userGuardiansQuery = query(
		userGuardiansRef,
		where('currentUser.uid', '==', uid),
	);

	const { status, data } = useFirestoreCollectionData(userGuardiansQuery);

	if (status === 'loading') {
		return null;
	}
	if (data) {
		data.forEach((guardian) => {
			guardians.push(
				{
					fieldName: 'Volle Naam',
					fieldValue: guardian.guardian.name,
					fieldType: 'text',
				},
				{
					fieldName: 'Van',
					fieldValue: guardian.guardian.surname,
					fieldType: 'text',
				},
				{
					fieldName: 'Noemnaam',
					fieldValue: guardian.guardian.preferredName,
					fieldType: 'text',
				},
				{
					fieldName: 'Verwantskap met kind',
					fieldValue: guardian.guardian.relationshipWithChild,
					fieldType: 'text',
				},
				{
					fieldName: 'Identiteidsnommer',
					fieldValue: guardian.guardian.identityNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'E-pos Adres',
					fieldValue: guardian.guardian.email,
					fieldType: 'text',
				},
				{
					fieldName: 'Selfoon Nommer',
					fieldValue: guardian.guardian.cellNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Werk Telfoon Nommer',
					fieldValue: guardian.guardian.workNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Huis Telfoon Nommer',
					fieldValue: guardian.guardian.homeNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Posbus Adres',
					fieldValue: guardian.guardian.PoBox,
					fieldType: 'text',
				},
				{
					fieldName: 'Straat Naam en Nommer',
					fieldValue: guardian.guardian.address,
					fieldType: 'text',
				},
				{
					fieldName: 'Area Naam',
					fieldValue: guardian.guardian.areaName,
					fieldType: 'text',
				},
				{
					fieldName: 'Area Kode',
					fieldValue: guardian.guardian.areaCode,
					fieldType: 'text',
				},
				{
					fieldName: 'Huwelik status',
					fieldValue: guardian.guardian.maritalStatus,
					fieldType: 'text',
				},
				{
					fieldName: 'Beroep',
					fieldValue: guardian.guardian.jobDescription,
					fieldType: 'text',
				},
				{
					fieldName: 'Werkgewer',
					fieldValue: guardian.guardian.employer,
					fieldType: 'text',
				},
				{
					fieldName: 'Ouer Mag Kind Oplaai',
					fieldValue: guardian.guardian.allowedToPickUpChild,
					fieldType: 'text',
				},
				{
					fieldName: 'Identiteitsdokument',
					fieldValue: guardian.guardian.identityDocumentUrl,
					fieldType: 'file',
				},
			);
		});
	}

	return guardians;
}

function GetDriverFormData(uid: string): TableDTO[] | null {
	const drivers: TableDTO[] = [];
	const userDriversRef = collection(useFirestore(), 'user_drivers');
	const userDriversQuery = query(
		userDriversRef,
		where('currentUser.uid', '==', uid),
	);

	const { status, data } = useFirestoreCollectionData(userDriversQuery);

	if (status === 'loading') {
		return null;
	}

	if (data) {
		data.forEach((driver) => {
			drivers.push(
				{
					fieldName: 'Naam',
					fieldValue: driver.driver.name,
					fieldType: 'text',
				},
				{
					fieldName: 'Van',
					fieldValue: driver.driver.surname,
					fieldType: 'text',
				},
				{
					fieldName: 'Identiteidsnommer',
					fieldValue: driver.driver.identityNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Verwantskap met kind',
					fieldValue: driver.driver.relationshipWithChild,
					fieldType: 'text',
				},
				{
					fieldName: 'Selfoon Nommer',
					fieldValue: driver.driver.cellNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Identiteitsdokument',
					fieldValue: driver.driver.identityDocumentUrl,
					fieldType: 'file',
				},
			);
		});
	}

	return drivers;
}

function GetDoctorFormData(uid: string): TableDTO[] | null {
	const doctors: TableDTO[] = [];
	const userDoctorsRef = collection(useFirestore(), 'user_doctor');
	const userDoctorsQuery = query(
		userDoctorsRef,
		where('currentUser.uid', '==', uid),
	);

	const { status, data } = useFirestoreCollectionData(userDoctorsQuery);

	if (status === 'loading') {
		return null;
	}

	if (data) {
		data.forEach((doctor) => {
			doctors.push(
				{
					fieldName: 'Naam',
					fieldValue: doctor.doctor.name,
					fieldType: 'text',
				},
				{
					fieldName: 'Van',
					fieldValue: doctor.doctor.surname,
					fieldType: 'text',
				},
				{
					fieldName: 'Selfoon Nommer',
					fieldValue: doctor.doctor.cellNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Naam van Mediesefond',
					fieldValue: doctor.doctor.medicalSchemeName,
					fieldType: 'text',
				},
				{
					fieldName: 'Nommer  van Mediesefond',
					fieldValue: doctor.doctor.medicalSchemeNumber,
					fieldType: 'text',
				},
				{
					fieldName: 'Allergeë',
					fieldValue: doctor.doctor.allergies,
					fieldType: 'text',
				},
				{
					fieldName: 'Aspekte',
					fieldValue: doctor.doctor.conditions,
					fieldType: 'text',
				},
			);
		});
	}

	return doctors;
}

export const DataTablePage = () => {
	const { docId, uid } = useParams();
	if (!docId || !uid) {
		return <>Child Does Not Exist</>;
	}

	const child = GetChildFormData(docId);
	const guardians = GetGuardiansFormData(uid);
	const drivers = GetDriverFormData(uid);
	const doctors = GetDoctorFormData(uid);

	return (
		<Box sx={{ mx: 2, mt: 12 }}>
			{child ? <DataTable rows={child} title={'Kind'} /> : <LoadingPage />}
			{guardians ? (
				<DataTable rows={guardians} title={'Ouers'} />
			) : (
				<LoadingPage />
			)}
			{drivers ? (
				<DataTable rows={drivers} title={'Drywers'} />
			) : (
				<LoadingPage />
			)}
			{doctors ? (
				<DataTable rows={doctors} title={'Dokters'} />
			) : (
				<LoadingPage />
			)}
		</Box>
	);
};
