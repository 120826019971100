import {
	GoogleAuthProvider,
	getAuth,
	sendEmailVerification,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';
import React, { useState } from 'react';

import { Alert } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactComponent as GoogleIcon } from '../../icons/GoogleIcon.svg';
import InputPassWordIconField from '../shared/InputFields/InputPasswordIconField';
import InputTextIconField from '../shared/InputFields/InputTextIconField';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const LoginBody = () => {
	const provider = new GoogleAuthProvider();
	const auth = getAuth();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [errorEmail, setErrorEmail] = useState(true);

	const [password, setPassword] = useState('');
	const [errorPassword, setErrorPassword] = useState(true);

	const [errorRegisterMessage, setErrorRegisterMessage] = useState('');
	const [errorRegister, setErrorRegister] = useState(false);

	const signInWithGoogle = () => {
		signInWithPopup(auth, provider)
			.then((userCredential) => {
				if (!userCredential.user.emailVerified) {
					sendEmailVerification(userCredential.user);
				}
				navigate('/paneelbord');
			})
			.catch((error) => {
				setErrorRegister(true);
				setErrorRegisterMessage(error.message);
			});
	};

	const signInWithEmail = () => {
		if (errorEmail || errorPassword) {
			return;
		}

		signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				navigate('/paneelbord');
			})
			.catch((error) => {
				setErrorRegister(true);
				setErrorRegisterMessage(error.message);
			});
	};

	return (
		<Box sx={{ mt: 1 }}>
			{errorRegister ? <Alert severity="error">{errorRegisterMessage}</Alert> : <></>}
			<InputTextIconField
				label="E-Pos Adres"
				name="email"
				type="email"
				icon={<AlternateEmailIcon />}
				value={email}
				setValue={setEmail}
				error={errorEmail}
				setError={setErrorEmail}
			/>
			<InputPassWordIconField
				label="Wagwoord"
				name="password"
				value={password}
				setValue={setPassword}
				error={errorPassword}
				setError={setErrorPassword}
				checkStrength={false}
			/>
			<Typography
				sx={{ mt: 2 }}
				variant="subtitle2"
				display="block"
				fontWeight={800}
				color={'#777'}
				gutterBottom
				align="center"
			>
				Ek het my
				<Link href="/wagwoord-vergeet" underline="none">
					{' wagwoord vergeet?'}
				</Link>
			</Typography>
			<Button onClick={() => signInWithEmail()} fullWidth variant="contained" sx={{ mt: 1 }}>
				Teken In
			</Button>
			<Box sx={{ my: 3 }}>
				<Typography variant="subtitle2" display="block" color={'#777'} gutterBottom align="center">
					Of, teken in met...
				</Typography>
			</Box>
			<Button variant="outlined" fullWidth startIcon={<GoogleIcon />} onClick={() => signInWithGoogle()}>
				Sign In with Google
			</Button>
			<Box sx={{ m: 2 }}>
				<Typography variant="subtitle2" display="block" fontWeight={800} color={'#777'} gutterBottom align="center">
					Nuut by Bambini Montessori?{' '}
					<Link href="/registeer" underline="none">
						Registreer
					</Link>
				</Typography>
			</Box>
		</Box>
	);
};

export default LoginBody;
