import { Box, Grid } from '@mui/material';

import Beleid_en_Prosedure from '../../documentation/TermsOfService.pdf';
import { DocumentationDTO } from '../../models/documentation/documentation';
import { DocumentationList } from '../../components/DocumentationList/DocumentationList';
import Foto_en_Video_beleid from '../../documentation/ImagePolicy.pdf';
import Popia from '../../documentation/PopiaDisclamer.pdf';
import Privaatheidsbeleid from '../../documentation/PrivacyPolicy.pdf';
import React from 'react';

export const DocumentPage = () => {
	const documents: DocumentationDTO[] = [
		{
			name: 'Popia',
			description:
				'General Terms and Conditions of Use of www.bambinimontessori.co.za',
			location: Popia,
		},
		{
			name: 'Privaatheidsbeleid',
			description: 'Bambini Montessori Privaatheidsbeleid',
			location: Privaatheidsbeleid,
		},
		{
			name: 'Foto en Video beleid',
			description: 'Bambini Montessori se Foto en Video beleid',
			location: Foto_en_Video_beleid,
		},
		{
			name: 'Beleid en Prosedure',
			description: 'Beleid en Prosedure',
			location: Beleid_en_Prosedure,
		},
	];

	return (
		<Box sx={{ mx: 2, mt: 12 }}>
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="stretch"
				spacing={2}
			>
				<Grid item xs={12}>
					<DocumentationList documents={documents} />
				</Grid>
			</Grid>
		</Box>
	);
};
