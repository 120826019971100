import { Timestamp, addDoc, getFirestore } from "firebase/firestore";

import { EmailDTO } from "../../models/email/email";
import { collection } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendContactFormEmail = createAsyncThunk(
  "/contactForm/sendContactFormEmail",
  async (email: EmailDTO) => {
    await addDoc(collection(getFirestore(), "contact_form_email"), {
      ...email,
      created: Timestamp.now(),
    });
  }
);
