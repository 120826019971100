import React from 'react';
import { AuthProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import RoutingPage from './pages/RoutingPage/RoutingPage';

function App() {
	const app = useFirebaseApp();
	const auth = getAuth(app);
	const firestore = getFirestore(app);

	return (
		<FirestoreProvider sdk={firestore}>
			<AuthProvider sdk={auth}>
				<RoutingPage />
			</AuthProvider>
		</FirestoreProvider>
	);
}

export default App;
