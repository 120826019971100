import React, { useEffect, useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { useNavigate, useParams } from 'react-router-dom';

import { DoctorFormGenerator } from './DoctorFormGenerator';
import LoadingPage from '../../shared/Loaders/LoadingPage';
import { UserDoctorDTO } from '../../../models/doctor/user-doctor';
import { doc } from 'firebase/firestore';

const DoctorForm = () => {
	const navigate = useNavigate();
	const { docId } = useParams();

	const doctorRef = doc(useFirestore(), 'user_doctor', docId as string);

	const { status, data } = useFirestoreDocData(doctorRef);

	const [form, setForm] = useState<JSX.Element>(<LoadingPage />);

	useEffect(() => {
		if (docId === 'nuwe-dokter') {
			setForm(<DoctorFormGenerator currentDoctor={null} />);
		} else if (data && status === 'success') {
			setForm(
				<DoctorFormGenerator currentDoctor={data.doctor as UserDoctorDTO} />,
			);
		} else if (!data && status === 'success') {
			navigate('/404');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, docId]);

	return form;
};

export default DoctorForm;
