import { Box, Grid, Typography } from '@mui/material';

import { BlueButton } from '../../Buttons/CustomButtons';
import React from 'react';
import { WidgetInfoCard } from '../../shared/Cards/WidgetInfoCard';
import drawPenWithBlob from '../../../icons/bambini_svg/winnerBoyWithBlueBlob.svg';
import { useNavigate } from 'react-router-dom';

export const AddChildToMyProfile = () => {
	const navigate = useNavigate();
	return (
		<WidgetInfoCard
			bgcolor={'#A5D5F9'}
			content={
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					sx={{ p: 2 }}
				>
					<Grid item xs={12} md={6}>
						<Typography
							variant="overline"
							sx={{
								fontWeight: 700,
								fontSize: 20,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							gutterBottom
						>
							Voeg Addisionele Kind
						</Typography>
						<Typography variant="body1" gutterBottom>
							Voeg addisionele kinders by on my profiel om hulle te registreer.
						</Typography>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<BlueButton
								variant="contained"
								color="secondary"
								sx={{ mt: 2 }}
								onClick={() => {
									navigate('/kind/nuwe-kind');
								}}
							>
								Voeg By
							</BlueButton>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box sx={{ maxHeight: 300, maxWidth: 300, m: 'auto' }}>
							<img src={drawPenWithBlob} alt="img"></img>
						</Box>
					</Grid>
				</Grid>
			}
		/>
	);
};
