import { Box, Card, GlobalStyles, Typography } from '@mui/material';
import React, { FC } from 'react';

import Avatar from '@mui/material/Avatar';

interface WidgetCardProps {
	title: string;
	icon: JSX.Element;
	content: JSX.Element;
	primaryColor: string;
	secondaryColor: string;
	bgcolor: string;
}

export const WidgetCard: FC<WidgetCardProps> = ({
	title,
	icon,
	content,
	primaryColor,
	secondaryColor,
	bgcolor,
}) => {
	return (
		<Card
			sx={{
				bgcolor: bgcolor,
				borderRadius: 5,
				boxShadow:
					'rgb(145 158 171 / 75%) 0px 0px 1px 0px, rgb(145 158 171 / 50%) 0px 2px 4px -1px',
			}}
		>
			<GlobalStyles
				styles={{
					body: { backgroundColor: '#f8fbfb' },
				}}
			/>
			<Box sx={{ display: 'flex', alignContent: 'center', pt: 2, pl: 2 }}>
				<Avatar
					variant="rounded"
					sx={{
						color: primaryColor,
						bgcolor: secondaryColor,
						mt: 0.5,
					}}
				>
					{icon}
				</Avatar>
				<Typography
					variant="overline"
					sx={{ pl: 2, fontWeight: 700, fontSize: 18 }}
				>
					{title}
				</Typography>
			</Box>
			{content}
		</Card>
	);
};
