import Button, { ButtonProps } from '@mui/material/Button';

import { styled } from '@mui/material/styles';

export const BlueButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFF',
	backgroundColor: '#68ACE0',
	'&:hover': {
		backgroundColor: '#C8E4FA',
		color: '#000',
	},
}));

export const PurpleButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFF',
	backgroundColor: '#AD8DC0',
	'&:hover': {
		backgroundColor: '#C7C7F2',
		color: '#000',
	},
}));

export const YellowButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFF',
	backgroundColor: '#FFAA72',
	'&:hover': {
		backgroundColor: '#F7E1BA',
		color: '#000',
	},
}));

export const GreenButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFF',
	backgroundColor: '#abd04d',
	'&:hover': {
		backgroundColor: '#C9DE93',
		color: '#000',
	},
}));
