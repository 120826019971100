import { BlueButton, YellowButton } from '../Buttons/CustomButtons';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

import Modal from '@mui/material/Modal';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { WidgetCard } from '../shared/Cards/WidgetCard';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	maxWidth: '95%',
	p: 2,
};

interface QuestionModalProps {
	title: string;
	question: string;
	open: boolean;
	yes: boolean;
	no: boolean;
	setOpen: (state: boolean) => void;
	updateFunction: (state: boolean) => void;
}

const QuestionModal: FC<QuestionModalProps> = ({
	title,
	question,
	open,
	yes,
	no,
	setOpen,
	updateFunction,
}) => {
	const handleClose = () => setOpen(false);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<WidgetCard
					title={title}
					icon={<QuestionMarkIcon />}
					primaryColor={'#FFAA72'}
					secondaryColor={'#F7E1BA'}
					bgcolor={'#fefffe'}
					content={
						<>
							<Typography variant="body1" gutterBottom sx={{ pt: 2, px: 3 }}>
								{question}
							</Typography>
							<YellowButton
								variant="contained"
								sx={{ m: 2 }}
								onClick={() => {
									updateFunction(yes);
									handleClose();
								}}
							>
								Ja
							</YellowButton>
							<BlueButton
								variant="contained"
								onClick={() => {
									updateFunction(no);
									handleClose();
								}}
							>
								Nee
							</BlueButton>
						</>
					}
				/>
			</Box>
		</Modal>
	);
};

export default QuestionModal;
