import React, { FC, useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import { Navigate } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { useAppSelector } from '../../hooks/hooks';

function AdminCheck(uid: string): boolean | null {
	const adminRef = doc(useFirestore(), 'admins', uid);

	const { status, data } = useFirestoreDocData(adminRef);

	if (status === 'loading') {
		return null;
	}

	return data.admin;
}

interface AdminProtectedRouteProps {
	page: JSX.Element;
}

const AdminProtectedRoute: FC<AdminProtectedRouteProps> = ({ page }) => {
	const [uid] = useState(useAppSelector((store) => store.currentUser.data.uid));
	const admin = AdminCheck(uid);

	if (admin === null) {
		return <LoadingPage />;
	} else if (admin === true) {
		return page;
	}

	return <Navigate to="/" replace />;
};
export default AdminProtectedRoute;
