import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

interface InputPassWordIconFieldProps {
	label: string;
	name: string;
	compareValue?: string;
	value: string;
	setValue: React.SetStateAction<any>;
	error: boolean;
	setError: React.SetStateAction<any>;
	checkStrength: boolean;
}

const InputPassWordIconField: FC<InputPassWordIconFieldProps> = ({
	label,
	name,
	value,
	compareValue,
	setValue,
	error,
	setError,
	checkStrength,
}) => {
	const [initial, setInitial] = useState(true);
	const [helperText, setHelperText] = useState('');
	const id = Math.floor(Math.random() * 10000).toString();

	const validateText = (newValue: string) => {
		setInitial(false);
		let isError = false;
		let errorMessage = '';

		setValue(newValue);

		if (compareValue && newValue !== compareValue) {
			isError = true;
			errorMessage = 'Wagwoord en Bevestig Wagwoord moet dieselfde wees';
		}

		if (checkStrength && newValue.length < 12) {
			isError = true;
			errorMessage = 'Wagwoord moet 12 karakters of langer wees';
		}

		setHelperText(errorMessage);
		setError(isError);
	};
	return (
		<TextField
			margin="normal"
			required
			fullWidth
			name={name}
			label={label}
			type="password"
			id={name + id}
			defaultValue={value}
			autoComplete={name}
			placeholder={label}
			helperText={helperText}
			error={error && !initial}
			onChange={(e) => validateText(e.target.value)}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<LockIcon />
					</InputAdornment>
				),
			}}
		/>
	);
};

export default InputPassWordIconField;
