import { Box, CircularProgress, GlobalStyles, Grid } from '@mui/material';

import React from 'react';

const LoadingPage = () => {
	return (
		<Box>
			<GlobalStyles
				styles={{
					body: { backgroundColor: '#fefffe' },
				}}
			/>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={3}>
					<CircularProgress disableShrink thickness={2} size={100} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default LoadingPage;
