import { Route, Routes } from 'react-router-dom';

import AdminProtectedRoute from './AdminProtectedRoute';
import AuthCard from '../../components/Auth/AuthCard';
import ChildForm from '../../components/Forms/Child/ChildForm';
import ContinueForms from '../../components/ContinueForms/ContinueForms';
import { DashboardPage } from '../DashboardPage/DashboardPage';
import { DataTablePage } from '../AdminPage/DataTablePage';
import DoctorForm from '../../components/Forms/Doctor/DoctorForm';
import { DocumentPage } from '../DocumentPage/DocumentPage';
import DriverForm from '../../components/Forms/DriverForm/DriverForm';
import ForgotPasswordBody from '../../components/Auth/ForgotPasswordBody';
import GuardianForm from '../../components/Forms/Guardian/GuardianForm';
import HomePage from '../HomePage/HomePage';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import LoginBody from '../../components/Auth/LoginBody';
import { ManageFamilyPage } from '../ManageFamilyPage/ManageFamilyPage';
import NavBar from '../../components/NavBar/NavBar';
import NotFoundPage from '../../components/shared/Loaders/NotFoundPage';
import ProtectedRoute from './ProtectedRoute';
import React, { useEffect } from 'react';
import RegisterBody from '../../components/Auth/RegisterBody';
import { currentUserActions } from '../../store/current-user/current-user-slice';
import { useAppDispatch } from '../../hooks/hooks';
import { useSigninCheck } from 'reactfire';
import { RegisterAgainPage } from '../RegisterAgainPage/RegisterAgainPage';
import { AdminDashboardPage } from '../AdminDashboardPage/AdminDashboardPage';
import { AdminPage } from '../AdminPage/AdminPage';
import { getUserForms } from '../../store/user-forms/user-forms-actions';
import { AdminDashboardSummaryTable } from '../AdminDashboardPage/AdminDashboardSummaryTable';

function LoginCheck() {
	const dispatch = useAppDispatch();
	const { status, data: signInCheckResult } = useSigninCheck();

	if (status === 'loading') {
		return 'loading';
	}

	if (signInCheckResult.signedIn === true) {
		const user = signInCheckResult.user;
		dispatch(
			currentUserActions.setCurrentUser({ email: user.email, uid: user.uid }),
		);
		return 'logged-in';
	} else {
		return 'not-logged-in';
	}
}

function RoutingPage() {
	const dispatch = useAppDispatch();

	const userStatus = LoginCheck();

	useEffect(() => {
		if (userStatus === "logged-in")
			dispatch(getUserForms());
	}, [userStatus]);

	return (
		<React.Fragment>
			{userStatus === 'loading' ? (
				<LoadingPage />
			) : (
				<React.Fragment>
					<NavBar userStatus={userStatus}></NavBar>
					<Routes>
						<Route
							path="/teken-in"
							element={<AuthCard title={'Teken In'} body={<LoginBody />} />}
						/>
						<Route
							path="/registeer"
							element={
								<AuthCard title={'Registreer'} body={<RegisterBody />} />
							}
						/>
						<Route
							path="/wagwoord-vergeet"
							element={
								<AuthCard
									title={'Wagwoord Vergeet'}
									body={<ForgotPasswordBody />}
								/>
							}
						/>
						<Route
							path="/registeer-kinders"
							element={<ProtectedRoute page={<RegisterAgainPage />} />}
						/>
						<Route path="/" element={<HomePage />} />
						<Route
							path="/kind/:docId"
							element={<ProtectedRoute page={<ChildForm />} />}
						/>
						<Route
							path="/ouer/:docId"
							element={<ProtectedRoute page={<GuardianForm />} />}
						/>
						<Route
							path="/drywer/:docId"
							element={<ProtectedRoute page={<DriverForm />} />}
						/>
						<Route
							path="/dokter/:docId"
							element={<ProtectedRoute page={<DoctorForm />} />}
						/>
						<Route
							path="/paneelbord"
							element={<ProtectedRoute page={<DashboardPage />} />}
						/>
						<Route
							path="/vorm"
							element={<ProtectedRoute page={<ContinueForms />} />}
						/>
						<Route
							path="/dokumentasie"
							element={<ProtectedRoute page={<DocumentPage />} />}
						/>
						<Route
							path="/bestuur-familie"
							element={<ProtectedRoute page={<ManageFamilyPage />} />}
						/>
						<Route
							path="/administrateur"
							element={<AdminProtectedRoute page={<AdminDashboardPage />} />}
						/>
						<Route
							path="/administrateur/wys-geregistreerde-kinders/:yearId"
							element={<AdminProtectedRoute page={<AdminPage />} />}
						/>
						<Route
							path="/administrateur/kind/:docId/:uid"
							element={<AdminProtectedRoute page={<DataTablePage />} />}
						/>
						<Route
							path="/administrateur/data-tabel"
							element={<AdminProtectedRoute page={<AdminDashboardSummaryTable />} />}
						/>
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default RoutingPage;
