import { Box, Chip, ListItem, ListItemButton, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Avatar from '@mui/material/Avatar';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import DangerousIcon from '@mui/icons-material/Dangerous';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { TableLinksDTO } from '../../models/table/tableLinks';
import { WidgetCard } from '../shared/Cards/WidgetCard';
import { useNavigate } from 'react-router-dom';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

interface AdminChildWidgetProps {
	userFormsData: TableLinksDTO[];
}

export const AdminChildWidget: FC<AdminChildWidgetProps> = ({
	userFormsData,
}) => {
	const navigate = useNavigate();

	const [searchData, setSearchData] = useState<TableLinksDTO[]>(userFormsData);

	useEffect(() => {
		setSearchData(userFormsData);
	}, [userFormsData]);

	const updateSearch = (searchValue: string) => {
		setSearchData(userFormsData.filter((data) => {
			if (data.child.preferredName.toLowerCase().includes(searchValue.toLowerCase())) {
				return data;
			}

			if (data.child.surname.toLowerCase().includes(searchValue.toLowerCase())) {
				return data;
			}
		}));
	}

	return (
		<WidgetCard
			title={'Kinders'}
			icon={<ChildFriendlyIcon />}
			primaryColor={'#FFAA72'}
			secondaryColor={'#F7E1BA'}
			bgcolor={'#fefffe'}
			content={
				<React.Fragment>
					<Box sx={{ m: 2 }}>
						<TextField id="outlined-basic" label="Search" variant="outlined" onChange={(e) => updateSearch(e.target.value)} fullWidth />
					</Box>
					<List sx={{ width: '100%', bgcolor: '#fefffe' }}>
						{userFormsData.length === 0 ? (
							<ListItemButton>
								<ListItemAvatar>
									<Avatar
										variant="rounded"
										sx={{
											color: '#ff1744',
											bgcolor: '#f4f2f8',
										}}
									>
										<DangerousIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									sx={{ font: '#ff1744' }}
									primary={'Geen rekords nie'}
								/>
							</ListItemButton>
						) : (
							searchData.map((userFormData, index) => {
								return (
									<React.Fragment key={index}>
										<ListItem>
											<ListItemButton
												onClick={() =>
													navigate(
														'/administrateur/kind/' +
														userFormData.child.docId +
														'/' +
														userFormData.docId,
													)
												}
											>
												<ListItemAvatar>
													<Avatar
														variant="rounded"
														sx={{
															color: '#FFCE72',
															bgcolor: '#f4f2f8',
														}}
													>
														<ChildCareIcon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={
														userFormData.child.preferredName +
														' ' +
														userFormData.child.surname
													}
												/>
												{!!userFormData.child.registrationYears ? (
													userFormData.child.registrationYears.map((year: string, indexYears: number) => {
														return (
															<Chip
																key={indexYears}
																label={year}
																size="small"
																sx={{ mx: 1 }}
															/>
														);
													})
												) : (
													<></>
												)}
												<ArrowForwardIosIcon />
											</ListItemButton>
											{userFormData.child.needsReview ? (
												<ManageSearchIcon color="primary" />
											) : (
												<>
													{userFormData.valid ? (
														<CheckBoxIcon color="success" />
													) : (
														<DangerousIcon color="warning" />
													)}
												</>
											)}
										</ListItem>
									</React.Fragment>
								);
							})
						)}
					</List >
				</React.Fragment >
			}
		/>
	);
};
