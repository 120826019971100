import React, { useEffect, useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { useNavigate, useParams } from 'react-router-dom';

import { ChildFormGenerator } from './ChildFormGenerator';
import LoadingPage from '../../shared/Loaders/LoadingPage';
import { UserChildrenDTO } from '../../../models/children/user-children';
import { doc } from 'firebase/firestore';

const ChildForm = () => {
	const navigate = useNavigate();
	const { docId } = useParams();

	const childRef = doc(useFirestore(), 'user_children', docId as string);

	const { status, data } = useFirestoreDocData(childRef);

	const [form, setForm] = useState<JSX.Element>(<LoadingPage />);

	useEffect(() => {
		if (docId === 'nuwe-kind') {
			setForm(<ChildFormGenerator currentChild={null} />);
		} else if (data && status === 'success') {
			setForm(
				<ChildFormGenerator currentChild={data.child as UserChildrenDTO} />,
			);
		} else if (!data && status === 'success') {
			navigate('/404');
		}
	}, [status, docId]);

	return form;
};

export default ChildForm;
