import {
  addUserDoctor,
  getUserDoctor,
  removeUserDoctor,
} from "./user-doctor-actions";

import { DataStatus } from "../../models/data/data-status";
import { UserDoctorDTO } from "../../models/doctor/user-doctor";
/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

interface DoctorStoreDTO {
  data: UserDoctorDTO[];
  dataStatus: DataStatus;
}

const initial: DoctorStoreDTO = {
  data: [],
  dataStatus: DataStatus.INITIAL,
};

const userDoctorSlice = createSlice({
  name: "user-doctor",
  initialState: initial,
  reducers: {
    logout(state: any) {
      state.data = [];
      state.dataStatus = DataStatus.INITIAL;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDoctor.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(getUserDoctor.fulfilled, (state, actions) => {
      state.data = actions.payload;
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(getUserDoctor.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(addUserDoctor.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(addUserDoctor.fulfilled, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(addUserDoctor.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(removeUserDoctor.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(removeUserDoctor.fulfilled, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(removeUserDoctor.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const userDoctorActions = userDoctorSlice.actions;

export default userDoctorSlice;
