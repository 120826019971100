import {
  Timestamp,
  deleteDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { collection, doc } from "firebase/firestore";

import { UserDoctorDTO } from "../../models/doctor/user-doctor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";

export const getUserDoctor = createAsyncThunk(
  "/userDoctor/getUserDoctor",
  async () => {
    const currentUser = await getAuth().currentUser;

    const userDoctor: UserDoctorDTO[] = [];

    if (currentUser) {
      const ref = collection(getFirestore(), "user_doctor");
      const q = query(ref, where("currentUser.uid", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        userDoctor.push({
          docId: doc.id,
          ...doc.data().drivers,
        });
      });
    }

    return userDoctor;
  }
);

export const addUserDoctor = createAsyncThunk(
  "/userDoctor/addUserDoctor",
  async (doctor: UserDoctorDTO) => {
    const currentUser = await getAuth().currentUser;

    await setDoc(doc(getFirestore(), "user_doctor", doctor.docId), {
      doctor,
      currentUser: {
        uid: currentUser?.uid,
        email: currentUser?.email,
      },
      created: Timestamp.now(),
      lastUpdated: Timestamp.now(),
    });
  }
);

export const removeUserDoctor = createAsyncThunk(
  "/userDoctor/removeUserDoctor",
  async (docId: string) => {
    await deleteDoc(doc(getFirestore(), "user_doctor", docId));
  }
);
