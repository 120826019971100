import { getChildrenData } from "./children-data-actions";

import { DataStatus } from "../../models/data/data-status";
import { createSlice } from "@reduxjs/toolkit";
import { ChildrenDataDTO } from "../../models/child_data_point/children_data";

interface ChildrenDataStoreDTO {
  data: ChildrenDataDTO;
  dataStatus: DataStatus;
}

const initial: ChildrenDataStoreDTO = {
  data: {
    children: [],
    registerCountData: [],
  },
  dataStatus: DataStatus.INITIAL,
};

const ChildrenDataSlice = createSlice({
  name: "children-data",
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChildrenData.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(getChildrenData.fulfilled, (state, actions) => {
      state.data = actions.payload;
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(getChildrenData.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const ChildrenDataActions = ChildrenDataSlice.actions;

export default ChildrenDataSlice;
