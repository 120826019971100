import React, { useEffect, useState } from 'react';
import {
	updateNoDriverState,
	updateSingleParentState,
} from '../../store/user-forms/user-forms-actions';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';

import { DataStatus } from '../../models/data/data-status';
import LoadingPage from '../shared/Loaders/LoadingPage';
import QuestionModal from '../Modals/QuestionModal';
import { UserFormsDataDTO } from '../../models/user-forms/user-forms-data';
import { useNavigate } from 'react-router-dom';
import { UserFormsDTO } from '../../models/user-forms/user-forms';
import { userFormsActions } from '../../store/user-forms/user-forms-slice';
import { formNeedsReviewCheck } from '../../pages/DashboardPage/form-need-review-helper';

const ContinueForms = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [singleParentOpen, setSingleParentOpen] = useState(false);
	const [noDriverOpen, setNoDriverOpen] = useState(false);

	const updateSingleParent = (state: boolean) => {
		dispatch(updateSingleParentState(state));
	};

	const updateNoDriverOpen = (state: boolean) => {
		dispatch(updateNoDriverState(state));
	};

	const userForms = useAppSelector((store) => store.userForms.data);
	const userFormsStatus = useAppSelector((store) => store.userForms.dataStatus);

	const userChildCheck = (children: UserFormsDataDTO[]) => {
		if (children.length > 0) {
			return true;
		}
		return false;
	};

	const userGuardianCheck = (
		guardians: UserFormsDataDTO[],
		singleParent: boolean | null,
	) => {
		if (guardians.length < 2) {
			if (singleParent && guardians.length === 1) {
				return true;
			}

			return false;
		}
		return true;
	};

	const userDriverCheck = (
		drivers: UserFormsDataDTO[],
		noDrivers: boolean | null,
	) => {
		if (drivers.length < 1) {
			if (noDrivers) {
				return true;
			}

			return false;
		}
		return true;
	};

	const userDoctorCheck = (doctor: UserFormsDataDTO[]) => {
		if (doctor.length > 0) {
			return true;
		}
		return false;
	};

	const validateFormCheck = (userForm: UserFormsDTO) => {
		if (userForm.children.length === 0) {
			return false;
		}

		if (userForm.guardians.length === 0) {
			return false;
		}

		if (
			userForm.guardians.length === 0 ||
			(userForm.guardians.length === 1 &&
				(!userForm.singleParent || userForm.singleParent === null))
		) {
			return false;
		}

		if (userForm.doctor.length === 0) {
			return false;
		}

		return true;
	};

	useEffect(() => {
		console.log("User Foms", userForms, userFormsStatus);
		if (userFormsStatus === DataStatus.COMPLETE) {
			if (userForms !== null) {
				console.log(userChildCheck(userForms.children));
				if (!userChildCheck(userForms.children)) {
					navigate('/kind/nuwe-kind');
					return;
				} else if (
					!userGuardianCheck(userForms.guardians, userForms.singleParent)
				) {
					if (userForms.singleParent === null) {
						setSingleParentOpen(true);
					} else {
						navigate('/ouer/nuwe-ouer');
						return;
					}
				} else if (!userDriverCheck(userForms.drivers, userForms.noDrivers)) {
					if (userForms.noDrivers === null) {
						setNoDriverOpen(true);
					} else {
						navigate('/drywer/nuwe-drywer');
						return;
					}
				} else if (!userDoctorCheck(userForms.doctor)) {
					navigate('/dokter/nuwe-dokter');
					return;
				} else {
					const formsComplete = validateFormCheck(userForms);
					dispatch(
						userFormsActions.updateValidateFormState(formsComplete),
					);

					navigate(formNeedsReviewCheck(userForms));
					return;
				}
			} else {
				navigate('/kind/nuwe-kind');
				return;
			}
		}
	}, [userFormsStatus, userForms, navigate, dispatch]);

	return (
		<>
			<QuestionModal
				title={'Vraag'}
				question={"Is u 'n enkel ouer of nie?"}
				open={singleParentOpen}
				yes={true}
				no={false}
				setOpen={setSingleParentOpen}
				updateFunction={updateSingleParent}
			/>
			<QuestionModal
				title={'Vraag'}
				question={
					'Is daar ander persone buite die ouers wat u kind/kinders mag op of aflaai?'
				}
				open={noDriverOpen}
				yes={false}
				no={true}
				setOpen={setNoDriverOpen}
				updateFunction={updateNoDriverOpen}
			/>
			<LoadingPage />
		</>
	);
};
export default ContinueForms;
