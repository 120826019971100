import { Alert, AlertTitle } from '@mui/material';
import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputTextIconField from '../shared/InputFields/InputTextIconField';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordBody = () => {
	const auth = getAuth();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [errorEmail, setErrorEmail] = useState(true);

	const [errorRegisterMessage, setErrorRegisterMessage] = useState('');
	const [errorRegister, setErrorRegister] = useState(false);

	const [emailSent, setEmailSent] = useState(false);

	const sendResetPasswordEmail = () => {
		if (errorEmail) {
			return;
		}

		sendPasswordResetEmail(auth, email)
			.then(() => {
				setEmailSent(true);
			})
			.catch((error) => {
				setErrorRegister(true);
				setErrorRegisterMessage(error.message);
			});
	};

	return (
		<Box sx={{ mt: 1 }}>
			{emailSent ? (
				<React.Fragment>
					<Alert severity="success">
						<AlertTitle>E-pos suksesvol gestuur</AlertTitle>
						Ons het 'n e-pos na <strong>{email}</strong> toe gestuur. U kan die
						instruksies volg om u se wagwoord te verander en dan met die nuwe
						wagwoord inteken.
					</Alert>
					<Typography variant="h6" gutterBottom component="div"></Typography>
					<Button
						onClick={() => navigate('/teken-in')}
						fullWidth
						variant="contained"
						sx={{ mt: 1 }}
					>
						Teken In
					</Button>
				</React.Fragment>
			) : (
				<React.Fragment>
					{errorRegister ? (
						<Alert severity="error">{errorRegisterMessage}</Alert>
					) : (
						<></>
					)}
					<InputTextIconField
						label="E-Pos Adres"
						name="email"
						type="email"
						icon={<AlternateEmailIcon />}
						value={email}
						setValue={setEmail}
						error={errorEmail}
						setError={setErrorEmail}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3 }}
						onClick={() => sendResetPasswordEmail()}
					>
						Stuur Instruksies
					</Button>
					<Box sx={{ m: 2 }}>
						<Typography
							variant="subtitle2"
							display="block"
							fontWeight={800}
							color={'#777'}
							gutterBottom
							align="center"
						>
							Ek het my wagwoord onthou?{' '}
							<Link href="/teken-in" underline="none">
								Teken In
							</Link>
						</Typography>
					</Box>
				</React.Fragment>
			)}
		</Box>
	);
};

export default ForgotPasswordBody;
