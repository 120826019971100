import {
	GenerateFileUploadButton,
	GenerateInputNumberField,
	GenerateInputSelectField,
	GenerateInputTextField,
} from '../FormBuilder/FormBuilderService';
import React, { FC, useState } from 'react';

import { FileUploadButtonDTO } from '../../../models/forms/file-upload-button';
import FormBuilder from '../FormBuilder/FormBuilder';
import FormContainer from '../FormBuilder/FormContainer';
import { FormContentDTO } from '../../../models/forms/form-content';
import { FormFieldData } from '../../../models/forms/form-field-data';
import { FormFieldTypes } from '../../../models/forms/form-field-types';
import { UserGuardianDTO } from '../../../models/guardian/user-guardian';
import { addUserGuardian } from '../../../store/user-guardians/user-guardian-actions';
import { v4 as uid } from 'uuid';
import { useAppDispatch } from '../../../hooks/hooks';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface GuardianFormGeneratorProps {
	currentGuardian: UserGuardianDTO | null;
}

export const GuardianFormGenerator: FC<GuardianFormGeneratorProps> = ({
	currentGuardian,
}) => {
	const dispatch = useAppDispatch();
	const [exists] = useState(currentGuardian ? true : false);
	const [docId] = useState(
		currentGuardian !== null ? currentGuardian.docId : uid(),
	);

	const consentOptions: string[] = ['Nee', 'Ja'];

	const name: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Volle Naam',
			currentGuardian != null ? currentGuardian.name : '',
			true,
		),
	};

	const surname: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Van',
			currentGuardian?.surname ? currentGuardian.surname : '',
			true,
		),
	};

	const preferredName: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Noemnaam',
			currentGuardian?.preferredName ? currentGuardian.preferredName : '',
			true,
		),
	};

	const identityNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Identiteitsnommer',
			currentGuardian?.identityNumber ? currentGuardian.identityNumber : '',
			true,
			13,
		),
	};

	const relationshipWithChild: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Verwantskap met kind',
			currentGuardian?.relationshipWithChild
				? currentGuardian.relationshipWithChild
				: '',
			true,
		),
	};

	const email: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'E-pos Adres',
			currentGuardian?.email ? currentGuardian.email : '',
			true,
			'email',
		),
	};

	const cellNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Selfoonnommer',
			currentGuardian?.cellNumber ? currentGuardian.cellNumber : '',
			true,
			10,
		),
	};

	const workNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Werk Telfoonnommer',
			currentGuardian?.workNumber ? currentGuardian.workNumber : '',
			false,
			10,
		),
	};

	const homeNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Huis Telfoonnommer',
			currentGuardian?.homeNumber ? currentGuardian.homeNumber : '',
			false,
			10,
		),
	};

	const PoBox: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Posbus Adres',
			currentGuardian?.PoBox ? currentGuardian.PoBox : '',
			true,
		),
	};

	const address: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Straat Naam en Nommer',
			currentGuardian?.address ? currentGuardian.address : '',
			true,
		),
	};

	const areaName: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Area Naam',
			currentGuardian?.areaName ? currentGuardian.areaName : '',
			true,
		),
	};

	const areaCode: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Area Kode',
			currentGuardian?.areaCode ? currentGuardian.areaCode : '',
			true,
			4,
		),
	};

	const maritalStatus: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Huwelik status',
			currentGuardian?.maritalStatus ? currentGuardian.maritalStatus : '',
			true,
		),
	};

	const jobDescription: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Beroep',
			currentGuardian?.jobDescription ? currentGuardian.jobDescription : '',
			true,
		),
	};

	const helperText =
		'Indien u, u eie besigheid het. Verskaf asseblief die naam van u besigheid';

	const employer: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Werkgewer',
			currentGuardian?.employer ? currentGuardian.employer : '',
			true,
			'text',
			undefined,
			helperText,
		),
	};

	const allowedToPickUpChild: FormFieldData<InputSelectFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_SELECT_FIELD,
		formFieldData: GenerateInputSelectField(
			'Ouer Mag Kind Oplaai',
			currentGuardian?.allowedToPickUpChild
				? currentGuardian.allowedToPickUpChild
				: '',
			consentOptions,
			true,
		),
	};

	const identityDocumentUrl: FormFieldData<FileUploadButtonDTO> = {
		formFieldType: FormFieldTypes.FILE_UPLOAD_BUTTON,
		formFieldData: GenerateFileUploadButton(
			'Identiteitsdokument',
			currentGuardian?.identityDocumentUrl
				? currentGuardian.identityDocumentUrl
				: '',
			docId,
			true,
		),
	};

	const guardianInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Ouer/Voog se Inligting',
	};

	const contactInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Ouer/Voog se Kontak Inligting',
	};

	const addressInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Ouer/Voog se Adres Inligting',
	};

	const otherInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Ouer/Voog se Ander Inligting',
	};

	const documentation: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Ouer/Voog se Dokumentasie',
	};

	const submit = (validForm: boolean) => {
		const guardian: UserGuardianDTO = {
			docId,
			name: name.formFieldData.value,
			surname: surname.formFieldData.value,
			preferredName: preferredName.formFieldData.value,
			relationshipWithChild: relationshipWithChild.formFieldData.value,
			identityNumber: identityNumber.formFieldData.value,
			email: email.formFieldData.value,
			cellNumber: cellNumber.formFieldData.value,
			workNumber: workNumber.formFieldData.value,
			homeNumber: homeNumber.formFieldData.value,
			PoBox: PoBox.formFieldData.value,
			address: address.formFieldData.value,
			areaName: areaName.formFieldData.value,
			areaCode: areaCode.formFieldData.value,
			maritalStatus: maritalStatus.formFieldData.value,
			jobDescription: jobDescription.formFieldData.value,
			employer: employer.formFieldData.value,
			allowedToPickUpChild: allowedToPickUpChild.formFieldData.value,
			identityDocumentUrl: identityDocumentUrl.formFieldData.file,
			validForm,
		};
		dispatch(addUserGuardian(guardian));
		if (exists) {
			dispatch(
				userFormsActions.updateGuardian({
					docId,
					preferredName: preferredName.formFieldData.value,
					surname: surname.formFieldData.value,
				}),
			);
		} else {
			dispatch(
				userFormsActions.addGuardian({
					docId,
					preferredName: preferredName.formFieldData.value,
					surname: surname.formFieldData.value,
				}),
			);
		}
	};

	const form: FormContentDTO = {
		formContent: [
			guardianInfo,
			name,
			surname,
			preferredName,
			relationshipWithChild,
			identityNumber,
			contactInfo,
			email,
			cellNumber,
			workNumber,
			homeNumber,
			addressInfo,
			PoBox,
			address,
			areaName,
			areaCode,
			otherInfo,
			maritalStatus,
			jobDescription,
			employer,
			allowedToPickUpChild,
			documentation,
			identityDocumentUrl,
		],
	};

	return (
		<FormContainer
			body={<FormBuilder form={form} submit={submit} exists={exists} />}
		/>
	);
};
