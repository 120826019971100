import React, { FC } from 'react';

import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';

interface ProtectedRouteProps {
	page: JSX.Element;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ page }) => {
	const uid = useAppSelector((store) => store.currentUser.data.uid);
	if (uid === '') {
		return <Navigate to="/" replace />;
	}
	return page;
};
export default ProtectedRoute;
