import {
  getUserForms,
  updateNoDriverState,
  updateSingleParentState,
} from "./user-forms-actions";

import { DataStatus } from "../../models/data/data-status";
import { UserFormsDTO } from "../../models/user-forms/user-forms";
import { UserFormsDataDTO } from "../../models/user-forms/user-forms-data";
import { createSlice } from "@reduxjs/toolkit";
import { UserFormsChildDataDTO } from "../../models/user-forms/user-forms-child-data";

const initialState: UserFormsDTO = {
  children: [],
  guardians: [],
  drivers: [],
  doctor: [],
  singleParent: null,
  noDrivers: null,
  formsComplete: false,
};
interface UserFormsStoreDTO {
  data: UserFormsDTO;
  dataStatus: DataStatus;
}

const initial: UserFormsStoreDTO = {
  data: initialState,
  dataStatus: DataStatus.INITIAL,
};

const userFormsSlice = createSlice({
  name: "user-forms",
  initialState: initial,
  reducers: {
    updateValidateFormState(state: any, actions: any) {
      if (state.data) {
        state.data.formsComplete = actions.payload;
      }
    },
    updateChildren(state: any, actions: any) {
      if (state.data) {
        state.data.children = actions.payload;
      }
    },
    updateChild(state: any, actions: any) {
      if (state.data) {
        const newChildren = state.data.children.map(
          (child: UserFormsChildDataDTO) => {
            if (child.docId === actions.payload.docId) {
              return actions.payload;
            }
            return child;
          }
        );

        state.data.children = newChildren;
      }
    },
    updateGuardian(state: any, actions: any) {
      if (state.data) {
        const newGuardians = state.data.guardians.map(
          (guardian: UserFormsDataDTO) => {
            if (guardian.docId === actions.payload.docId) {
              return actions.payload;
            }
            return guardian;
          }
        );
        state.data.guardians = newGuardians;
      }
    },
    updateDoctor(state: any, actions: any) {
      if (state.data) {
        const newDoctors = state.data.doctor.map(
          (doctorItem: UserFormsDataDTO) => {
            if (doctorItem.docId === actions.payload.docId) {
              return actions.payload;
            }
            return doctorItem;
          }
        );

        state.data.doctor = newDoctors;
      }
    },
    updateDriver(state: any, actions: any) {
      if (state.data) {
        const newDrivers = state.data.drivers.map(
          (driver: UserFormsDataDTO) => {
            if (driver.docId === actions.payload.docId) {
              return actions.payload;
            }
            return driver;
          }
        );

        state.data.drivers = newDrivers;
      }
    },
    addChild(state: any, actions: any) {
      state.data.children.push(actions.payload);
    },
    addGuardian(state: any, actions: any) {
      if (state.data) {
        state.data.guardians.push(actions.payload);
      }
    },
    addDriver(state: any, actions: any) {
      if (state.data) {
        state.data.drivers.push(actions.payload);
      }
    },
    addDoctor(state: any, actions: any) {
      if (state.data) {
        state.data.doctor.push(actions.payload);
      }
    },
    removeUserChild(state: any, actions: any) {
      if (state.data) {
        state.data.children = state.data.children.filter(
          (child: UserFormsDataDTO) => actions.payload !== child.docId
        );
      }
    },
    removeUserGuardian(state: any, actions: any) {
      if (state.data) {
        state.data.guardians = state.data.guardians.filter(
          (guardian: UserFormsDataDTO) => actions.payload !== guardian.docId
        );
      }
    },
    removeUserDriver(state: any, actions: any) {
      if (state.data) {
        state.data.drivers = state.data.drivers.filter(
          (driver: UserFormsDataDTO) => actions.payload !== driver.docId
        );
      }
    },
    removeUserDoctor(state: any, actions: any) {
      if (state.data) {
        state.data.doctor = state.data.doctor.filter(
          (doc: UserFormsDataDTO) => actions.payload !== doc.docId
        );
      }
    },
    logout(state: any) {
      state.data = null;
      state.dataStatus = DataStatus.INITIAL;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserForms.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(getUserForms.fulfilled, (state, actions) => {
      if (actions.payload !== null) {
        state.data = actions.payload;
      }

      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(getUserForms.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(updateSingleParentState.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(updateSingleParentState.fulfilled, (state, action) => {
      if (state.data) {
        state.data.singleParent = action.payload;
      }
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(updateSingleParentState.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(updateNoDriverState.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(updateNoDriverState.fulfilled, (state, action) => {
      if (state.data) {
        state.data.noDrivers = action.payload;
      }
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(updateNoDriverState.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const userFormsActions = userFormsSlice.actions;

export default userFormsSlice;
