import {
	GenerateFileUploadButton,
	GenerateInputDateField,
	GenerateInputNumberField,
	GenerateInputSelectField,
	GenerateInputTextField,
} from '../FormBuilder/FormBuilderService';
import React, { FC, useState } from 'react';

import { FileUploadButtonDTO } from '../../../models/forms/file-upload-button';
import FormBuilder from '../FormBuilder/FormBuilder';
import FormContainer from '../FormBuilder/FormContainer';
import { FormContentDTO } from '../../../models/forms/form-content';
import { FormFieldData } from '../../../models/forms/form-field-data';
import { FormFieldTypes } from '../../../models/forms/form-field-types';
import { InputDateFieldDTO } from '../../../models/forms/form-date-field';
import { UserChildrenDTO } from '../../../models/children/user-children';
import { addUserChild, updateUserChild } from '../../../store/user-children/user-children-actions';
import { v4 as uid } from 'uuid';
import { useAppDispatch } from '../../../hooks/hooks';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface ChildFormGeneratorProps {
	currentChild: UserChildrenDTO | null;
}

export const ChildFormGenerator: FC<ChildFormGeneratorProps> = ({
	currentChild,
}) => {
	const dispatch = useAppDispatch();
	const [exists] = useState(!!currentChild ? true : false);
	const [docId] = useState(!!currentChild ? currentChild.docId : uid());

	const genderOptions: string[] = ['Vroulik', 'Manlik'];
	const consentOptions: string[] = ['Nee', 'Ja'];
	const consentOptionsPhoto: string[] = [
		"Nee, mag nie foto's van my kind neem nie",
		"Ja, mag foto's neem van my kind",
	];

	const name: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Volle Naam',
			currentChild != null ? currentChild.name : '',
			true,
		),
	};

	const surname: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Van',
			currentChild?.surname ? currentChild.surname : '',
			true,
		),
	};

	const preferredName: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Noemnaam',
			currentChild?.preferredName ? currentChild.preferredName : '',
			true,
		),
	};

	const gender: FormFieldData<InputSelectFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_SELECT_FIELD,
		formFieldData: GenerateInputSelectField(
			'Geslag',
			currentChild?.gender ? currentChild.gender : '',
			genderOptions,
			true,
		),
	};

	const identityNumber: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Identiteitsnommer',
			currentChild?.identityNumber ? currentChild.identityNumber : '',
			true,
			13,
		),
	};

	const dateOfBirth: FormFieldData<InputDateFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_DATE_FIELD,
		formFieldData: GenerateInputDateField(
			'Geboortedatum',
			currentChild?.dateOfBirth ? currentChild.dateOfBirth : null,
			true,
		),
	};

	const address: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Straat Naam en Nommer',
			currentChild?.address ? currentChild.address : '',
			true,
		),
	};

	const areaName: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Area Naam',
			currentChild?.areaName ? currentChild.areaName : '',
			true,
		),
	};

	const areaCode: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_NUMBER_FIELD,
		formFieldData: GenerateInputNumberField(
			'Area Kode',
			currentChild?.areaCode ? currentChild.areaCode : '',
			true,
			4,
		),
	};

	const homeLanguage: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Huistaal',
			currentChild?.homeLanguage ? currentChild.homeLanguage : '',
			true,
		),
	};

	const previousSchool: FormFieldData<InputTextFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_TEXT_FIELD,
		formFieldData: GenerateInputTextField(
			'Vorige Skool Naam',
			currentChild?.previousSchool ? currentChild.previousSchool : '',
			false,
		),
	};

	const afterSchoolCare: FormFieldData<InputSelectFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_SELECT_FIELD,
		formFieldData: GenerateInputSelectField(
			'Ek stel belang in nasog',
			currentChild?.afterSchoolCare ? currentChild.afterSchoolCare : '',
			consentOptions,
			true,
		),
	};

	const photosPolicy: FormFieldData<InputSelectFieldDTO> = {
		formFieldType: FormFieldTypes.INPUT_SELECT_FIELD,
		formFieldData: GenerateInputSelectField(
			'Foto Beleid',
			currentChild?.photosPolicy ? currentChild.photosPolicy : '',
			consentOptionsPhoto,
			true,
		),
	};

	const immunityCertificate: FormFieldData<FileUploadButtonDTO> = {
		formFieldType: FormFieldTypes.FILE_UPLOAD_BUTTON,
		formFieldData: GenerateFileUploadButton(
			'Immuniteitsertifikaat',
			currentChild?.immunityCertificateUrl
				? currentChild.immunityCertificateUrl
				: '',
			docId,
			true,
		),
	};

	const birthCertificate: FormFieldData<FileUploadButtonDTO> = {
		formFieldType: FormFieldTypes.FILE_UPLOAD_BUTTON,
		formFieldData: GenerateFileUploadButton(
			'Geboortesertifikaat',
			currentChild?.dateOfBirthCertificateUrl
				? currentChild.dateOfBirthCertificateUrl
				: '',
			docId,
			true,
		),
	};

	const childInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Kind se Inligting',
	};

	const addressInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Adres Inligting',
	};

	const otherInfo: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Ander Inligting',
	};

	const documentation: FormFieldData<string> = {
		formFieldType: FormFieldTypes.FORM_SECTION_TITLE,
		formFieldData: 'Dokumentasie',
	};

	const submit = (validForm: boolean) => {
		const child: UserChildrenDTO = {
			docId,
			name: name.formFieldData.value,
			surname: surname.formFieldData.value,
			preferredName: preferredName.formFieldData.value,
			gender: gender.formFieldData.value,
			identityNumber: identityNumber.formFieldData.value,
			dateOfBirth: dateOfBirth.formFieldData.value,
			address: address.formFieldData.value,
			areaName: areaName.formFieldData.value,
			areaCode: areaCode.formFieldData.value,
			homeLanguage: homeLanguage.formFieldData.value,
			previousSchool: previousSchool.formFieldData.value,
			afterSchoolCare: afterSchoolCare.formFieldData.value,
			photosPolicy: photosPolicy.formFieldData.value,
			immunityCertificateUrl: immunityCertificate.formFieldData.file,
			dateOfBirthCertificateUrl: birthCertificate.formFieldData.file,
			validForm,
		};

		if (exists) {
			dispatch(updateUserChild(child));
			dispatch(
				userFormsActions.updateChild({
					docId,
					preferredName: preferredName.formFieldData.value,
					surname: surname.formFieldData.value,
					needsReview: false,
				}),
			);
		} else {
			dispatch(addUserChild(child));
			dispatch(
				userFormsActions.addChild({
					docId,
					preferredName: preferredName.formFieldData.value,
					surname: surname.formFieldData.value,
					needsReview: false,
				}),
			);
		}
	};

	const form: FormContentDTO = {
		formContent: [
			childInfo,
			name,
			surname,
			preferredName,
			gender,
			identityNumber,
			dateOfBirth,
			addressInfo,
			address,
			areaName,
			areaCode,
			otherInfo,
			homeLanguage,
			previousSchool,
			afterSchoolCare,
			photosPolicy,
			documentation,
			immunityCertificate,
			birthCertificate,
		],
	};
	return (
		<FormContainer
			body={<FormBuilder form={form} submit={submit} exists={exists} />}
		/>
	);
};
