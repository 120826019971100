import { IconButton, ListItem, ListItemButton } from '@mui/material';
import React, { FC } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Avatar from '@mui/material/Avatar';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import { PurpleButton } from '../../Buttons/CustomButtons';
import { UserFormsDataDTO } from '../../../models/user-forms/user-forms-data';
import { WidgetCard } from '../../shared/Cards/WidgetCard';
import { removeUserDriver } from '../../../store/user-drivers/user-drivers-actions';
import { useAppDispatch } from '../../../hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { userFormsActions } from '../../../store/user-forms/user-forms-slice';

interface DriverTableDisplayWidgetProps {
	userFormsData: UserFormsDataDTO[];
}

export const DriverTableDisplayWidget: FC<DriverTableDisplayWidgetProps> = ({ userFormsData }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const removeData = (docId: string) => {
		dispatch(userFormsActions.removeUserDriver(docId));
		dispatch(removeUserDriver(docId));
	};

	return (
		<WidgetCard
			title={'Drywers'}
			icon={<DirectionsCarIcon />}
			primaryColor={'#AD8DC0'}
			secondaryColor={'#C7C7F2'}
			bgcolor={'#fefffe'}
			content={
				<>
					<List sx={{ width: '100%', bgcolor: '#fefffe' }}>
						{userFormsData.length === 0 ? (
							<ListItemButton>
								<ListItemAvatar>
									<Avatar
										variant="rounded"
										sx={{
											color: '#ff1744',
											bgcolor: '#f4f2f8',
										}}
									>
										<DangerousIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									sx={{ font: '#ff1744' }}
									primary={'Geen rekords nie'}
								/>
							</ListItemButton>
						) : (
							userFormsData.map((userFormData, index) => {
								return (
									<React.Fragment key={index}>
										<ListItem>
											<ListItemButton
												onClick={() =>
													navigate('/drywer/' + userFormData.docId)
												}
											>
												<ListItemAvatar>
													<Avatar
														variant="rounded"
														sx={{
															color: '#AD8DC0',
															bgcolor: '#f4f2f8',
														}}
													>
														<PersonIcon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={
														userFormData.preferredName +
														' ' +
														userFormData.surname
													}
												/>
												<ArrowForwardIosIcon />
											</ListItemButton>
											<IconButton
												color="error"
												component="span"
												onClick={() => removeData(userFormData.docId)}
											>
												<DeleteIcon />
											</IconButton>
										</ListItem>
									</React.Fragment>
								);
							})
						)}
					</List>
					<PurpleButton
						variant="contained"
						sx={{ m: 2 }}
						startIcon={<AddBoxIcon />}
						onClick={() => {
							navigate('/drywer/nuwe-drywer');
						}}
					>
						Drywer
					</PurpleButton>
				</>
			}
		/>
	);
};
