import { Box, Grid, Typography } from '@mui/material';

import { GreenButton, PurpleButton } from '../../Buttons/CustomButtons';
import { WidgetInfoCard } from '../../shared/Cards/WidgetInfoCard';
import childScienceWithBlob from '../../../icons/bambini_svg/childScienceWithBlob.svg';
import { useNavigate } from 'react-router-dom';


export const ShowSummaryDataViewWidget = () => {
  const navigate = useNavigate();
  return (
    <WidgetInfoCard
      bgcolor={'#C9DE93'}
      content={
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="overline"
              sx={{
                fontWeight: 700,
                fontSize: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              gutterBottom
            >
              Data Tabel
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <GreenButton
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={() => {
                  navigate(`/administrateur/data-tabel`);
                }}
              >
                Wys Data Tabel
              </GreenButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxHeight: 300, maxWidth: 300, m: 'auto' }}>
              <img src={childScienceWithBlob} alt="img"></img>
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};
