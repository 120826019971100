import './HomePage.css';
import './App.css';

import React, { useState } from 'react';

import Beleid_en_Prosedure from '../../documentation/TermsOfService.pdf';
import angleGirl from '../../icons/bambini_svg/angleGirl.svg';
import background_1 from '../../icons/bambini_svg/background-1.svg';
import background_2 from '../../icons/bambini_svg/background-2.svg';
import background_3 from '../../icons/bambini_svg/background-3.svg';
import booksBoy from '../../icons/bambini_svg/booksBoy.svg';
import globeBoy from '../../icons/bambini_svg/globeBoy.svg';
import gotAnA from '../../icons/bambini_svg/gotAnA.svg';
import recycleGirl from '../../icons/bambini_svg/recycleGirl.svg';
import { sendContactFormEmail } from '../../store/contact-form/contact-form-actions';
import { useAppDispatch } from '../../hooks/hooks';
import winnerBoy from '../../icons/bambini_svg/winnerBoy.svg';

export default function HomePage() {
	const dispatch = useAppDispatch();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	const [emailSent, setEmailSent] = useState(false);

	const sendEmail = () => {
		setEmailSent(true);
		dispatch(
			sendContactFormEmail({
				name,
				email,
				subject,
				message,
			}),
		);
	};
	return (
		<>
			<div className="container-0" id="c1">
				<div>
					<div className="socialMedia">
						<a
							className="nav-link"
							style={{ color: '#000' }}
							href="tel:+27721944374"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-telephone-fill"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
								/>
							</svg>
							Sel: 072 1944 374
						</a>
						<a
							className="nav-link"
							style={{ color: '#000' }}
							href="https://m.facebook.com/Bambini-Montessori-Polokwane-106940352046093"
							target={"_blank"}
						>
							<svg
								style={{ color: '#3b5998' }}
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								fill="currentColor"
								className="bi bi-facebook"
								viewBox="0 0 16 16"
							>
								<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
							</svg>
							Facebook
						</a>
					</div>
					<div className="coverPage"></div>
				</div>
			</div>
			<div className="content-1" id="c2">
				<p className="h2">Meer oor ons</p>
				<div className="row">
					<div className="col-sm-4">
						<img className="mx-auto d-block" src={globeBoy} alt="" />
						<h4>Sentraal Polokwane</h4>
					</div>
					<div className="col-sm-4">
						<img className="mx-auto d-block" src={angleGirl} alt="" />
						<h4>Christelike Beginsels</h4>
					</div>
					<div className="col-sm-4">
						<img className="mx-auto d-block" src={booksBoy} alt="" />
						<h4>Montessori Program</h4>
					</div>
					<div className="col-sm-4">
						<img className="mx-auto d-block" src={recycleGirl} alt="" />
						<h4>Veilige omgewing</h4>
					</div>
					<div className="col-sm-4">
						<img className="mx-auto d-block" src={gotAnA} alt="" />
						<h4>Geregistreerde Skool</h4>
					</div>
					<div className="col-sm-4">
						<img className="mx-auto d-block" src={winnerBoy} alt="" />
						<h4>Goue Sertifikaat</h4>
					</div>
				</div>
			</div>
			<div className="content-2" id="c3">
				<div className="row">
					<div className="col-sm-6">
						<img src={background_1} alt="" />
					</div>
					<div className="col-sm-6">
						<table
							className="table table-borderless content3Table"
							style={{ maxHeight: 420 }}
						>
							<tbody>
								<tr>
									<th colSpan={2}>
										<p className="h2 headingContent2">Skool Inligting</p>
									</th>
								</tr>
								<tr>
									<th>Oggendskool tye:</th>
									<td>7uur tot 1uur</td>
								</tr>
								<tr>
									<th>Middagsorg tye:</th>
									<td>1 uur tot 5 uur (Maandag tot Vrydag)</td>
								</tr>
								<tr>
									<th>Vakansiesorg:</th>
									<td>Opsioneel</td>
								</tr>
								<tr>
									<th>Kwartale:</th>
									<td>Die selfde as Limpopo</td>
								</tr>
								<tr>
									<th>Taalmedium:</th>
									<td>Afrikaans</td>
								</tr>
								<tr>
									<th>Ouderdomme:</th>
									<td>6 maande tot gr 00</td>
								</tr>
								<tr>
									<th>Klasse:</th>
									<td>4 klasse</td>
								</tr>
								<tr>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="content-3" id="c4">
				<div className="row">
					<div className="col-sm-6">
						<table
							className="table table-borderless"
							style={{ maxHeight: 725 }}
						>
							<tbody>
								<tr>
									<th>
										<p className="h2 headingContent3">
											Wat is vir ons belangrik?
										</p>
									</th>
								</tr>
								<tr>
									<td>
										Die <b>geheelontwikkeling</b> van leerders met 'n
										gebalanseerde program wat werk, speel en ontspanning
										insluit.
									</td>
								</tr>
								<tr>
									<td>
										<b>Intensiewe aandag en kwaliteit onderrig</b> aan elke
										leerder.
									</td>
								</tr>
								<tr>
									<td>
										<b>Sensoriese stimulasie</b> sodat leerders al hulle sintuie
										gebruik om die omgewing te verken.
									</td>
								</tr>
								<tr>
									<td>
										<b>Individualisme en kreatiwiteit</b> - Daar word daagliks
										geleentheid gegee om te eksperimenteer om sodoende die
										kreatiwiteit van elke leerder aan te moedig.
									</td>
								</tr>
								<tr>
									<td>
										<b>Evaluasie</b> - Leerders word daagliks gelei om te groei
										en te ontwikkel. Evaluasie is in die temas ingewerk en word
										daagliks informeel gedoen.
									</td>
								</tr>
								<tr>
									<td>
										Om 'n leerder met{' '}
										<b>selfvertroue, waagmoed en vaardighede</b> graad R toe te
										stuur.
									</td>
								</tr>
								<tr>
									<td></td>
								</tr>
							</tbody>
						</table>
						<div className="beleidDiv">
							<p className="h5">Alles wat u wil weet oor ons skool:</p>
							<br />
							<a
								className="indexBtn"
								href={Beleid_en_Prosedure}
								rel="noreferrer"
								target="_blank"
							>
								Beleid en Prosedure
							</a>
						</div>
					</div>
					<div className="col-sm-6">
						<img src={background_3} alt="" />
					</div>
				</div>
			</div>

			<div className="content-4" id="c5">
				<div className="row flex-column-reverse flex-sm-row">
					<div className="col-sm-6 col-sm-push-6">
						<img src={background_2} alt="" />
					</div>
					<div className="col-sm-6 col-sm-pull-6">
						<table
							className="table table-borderless content4Table"
							style={{ maxHeight: 860 }}
						>
							<tbody>
								<tr>
									<th>
										<p className="h2 headingContent4">Finansies (2025):</p>
									</th>
								</tr>
								<tr>
									<th style={{ textAlign: 'left' }}>Oggendskool</th>
								</tr>
								<tr>
									<td>
										<ul>
											<li>R3600 x 11</li>
											<li>Tweede en derde leerders kry 5% afslag op oggendskool gelde. Sluit alle kostes in. Geen ekstra fooie nie.</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th style={{ textAlign: 'left' }}>Middagsorg (Opsioneel)</th>
								</tr>
								<tr>
									<td>
										<ul>
											<li>R1300 per maand of R65 per dag</li>
											<li>5 dae per week (Maandag tot Vrydag).</li>
											<li>Etes en versnapperrings ingesluit.</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th style={{ textAlign: 'left' }}>Vakansiesorg (Opsioneel)</th>
								</tr>
								<tr>
									<td>
										<ul>
											<li>R100 per oggend</li>
											<li>R65 per middag</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th style={{ textAlign: 'left' }}>Registrasie - R800 eenmalig</th>
								</tr>
								<tr>
									<th style={{ textAlign: 'left' }}>Deposito</th>
								</tr>
								<tr>
									<td>
										<ul>
											<li>R3000</li>
											<li>Word as deel van Januarie skoolgeld bereken. Slegs nuwe registrasies.</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th style={{ textAlign: 'left' }}>Fooie sluit in:</th>
								</tr>
								<tr>
									<td>
										<ul>
											<li>Skryfbehoeftes en apparaat</li>
											<li>Verjaarsdag/ Weeklikse bak en brou</li>
											<li>
												As deel van die program: (Kuns, Grootmotoriek, Engels,
												Bambini Musiek, *Playball)
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th>
										*Alle gelde vir die jaar is betaalbaar voor of op die 8ste
										November 2025. Klasgelde moet voor of op die 3de van elke
										maand betaal word.
									</th>
								</tr>
							</tbody>
						</table>
						<div className="beleidDiv">
							<p style={{ maxWidth: '75%', margin: 'auto' }} className="h5">
								Vir meer inligting oor ons skoolfooie (bl. 17):{' '}
							</p>
							<br />
							<a
								className="geldBtn"
								href={Beleid_en_Prosedure}
								rel="noreferrer"
								target="_blank"
							>
								Beleid en Prosedure
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="content-5" id="c6">
				<div className="row">
					<div className="col-sm contactForm">
						<p id="kontak" className="h2 headingContent5">
							Kontak ons
						</p>
						<div id="form-ultra">
							{emailSent ? (
								<div className="alert alert-success" role="alert">
									<strong>E-pos is gestuur!</strong> Ons sal so vinnig as
									moontlik u antwoord.
								</div>
							) : (
								<></>
							)}

							<div className="form-group">
								<label> Naam en Van: </label>
								<input
									type="text"
									className="form-control"
									placeholder="Naam en Van"
									name="name"
									onChange={(event) => setName(event.target.value)}
								/>
							</div>
							<div className="form-group">
								<label> E-pos adres: </label>
								<input
									type="text"
									className="form-control"
									placeholder="E-pos adres"
									name="email"
									onChange={(event) => setEmail(event.target.value)}
								/>
							</div>
							<div className="form-group">
								<label> Onderwerp: </label>
								<input
									type="text"
									className="form-control"
									placeholder="Onderwerp"
									name="subject"
									onChange={(event) => setSubject(event.target.value)}
								/>
							</div>
							<div className="form-group">
								<label> Boodskap: </label>
								<textarea
									className="form-control"
									placeholder="Boodskap"
									name="message"
									onChange={(event) => setMessage(event.target.value)}
								></textarea>
							</div>

							<button
								onClick={() => {
									sendEmail();
								}}
							>
								Stuur
							</button>
						</div>
					</div>
				</div>
			</div>

			<footer>
				<div className="footerLinks">
					<a
						className="nav-link footerLink h6"
						style={{ color: '#000' }}
						href="tel:+27721944374"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-telephone-fill"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
							/>
						</svg>
						Sel: 072 1944 374
					</a>
					<a
						className="nav-link footerLink h6"
						style={{ color: '#000' }}
						href="https://m.facebook.com/Bambini-Montessori-Polokwane-106940352046093"
						target={"_blank"}
					>
						<svg
							style={{ color: '#3b5998' }}
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							className="bi bi-facebook"
							viewBox="0 0 16 16"
						>
							<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
						</svg>
						Facebook
					</a>
					<p className="footerCopy h6">
						Copyright&#169; 2020. All Rights Reserved
					</p>
				</div>
			</footer>
		</>
	);
}
