import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import { AdminWidget } from '../../components/Widgets/Cards/AdminWidget';
import { CompleteFormsWidget } from '../../components/Widgets/Cards/CompleteFormsWidget';
import { DataStatus } from '../../models/data/data-status';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import { ManageFormsWidget } from '../../components/Widgets/Cards/ManageFamilyWidget';
import { ViewDocumentationWidget } from '../../components/Widgets/Cards/ViewDocumentationWidget';
import { adminUserActions } from '../../store/admin-user/admin-user-slice';
import { doc } from 'firebase/firestore';
import { RegisterWidget } from '../../components/Widgets/Cards/RegisterWidget';
import { FormsNeedReviewWidget } from '../../components/Widgets/Cards/FormsNeedReviewWidget';
import {
	formNeedsReviewCheck,
} from './form-need-review-helper';
import { getUserForms } from '../../store/user-forms/user-forms-actions';
import { AddChildToMyProfile } from '../../components/Widgets/Cards/AddChildToMyProfile';

export const DashboardPage = () => {
	const dispatch = useAppDispatch();


	const data = useAppSelector((store) => store.userForms.data);
	const userFormsStatus = useAppSelector((store) => store.userForms.dataStatus);
	const uid = useAppSelector((store) => store.currentUser.data.uid);
	const admin = useAppSelector((store) => store.adminUser.data.admin);

	const [formComplete, setFormComplete] = useState<boolean>(false);
	const [formReview, setFormReview] = useState<string>('');

	useEffect(() => {
		if (data) {
			setFormComplete(data.formsComplete);
			setFormReview(formNeedsReviewCheck(data));
		}
	}, [data]);

	return (
		<Box sx={{ mt: 12, width: '100vw' }}>
			{userFormsStatus === DataStatus.LOADING ? (
				<LoadingPage />
			) : (
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					sx={{ px: 3, pb: 2 }}
				>
					{FormComplete(formComplete)}
					{FormNeedReview(formReview)}
					<Grid item xs={12} md={6}>
						<ManageFormsWidget />
					</Grid>
					<Grid item xs={12} md={6}>
						<ViewDocumentationWidget />
					</Grid>
					{admin ? (
						<Grid item xs={12} md={6}>
							<AdminWidget />
						</Grid>
					) : (
						<></>
					)}
				</Grid>
			)}
		</Box>
	);
};

const FormComplete = (formComplete: boolean) => {
	return (
		<>
			{
				formComplete ?
					(
						<>
							<Grid item xs={12} md={6}>
								<RegisterWidget />
							</Grid>
							<Grid item xs={12} md={6}>
								<AddChildToMyProfile />
							</Grid>
						</>
					)
					:
					<Grid item xs={12} md={6}>
						<CompleteFormsWidget />
					</Grid>
			}
		</>
	);
};

const FormNeedReview = (formReview: string) => {
	return (
		<>
			{
				formReview !== '/paneelbord' ?
					(<Grid item xs={12} md={6}>
						<FormsNeedReviewWidget url={formReview} />
					</Grid>) :
					<></>
			}
		</>
	);
};
