import adminUserSlice from "./admin-user/admin-user-slice";
import { configureStore } from "@reduxjs/toolkit";
import currentUserSlice from "./current-user/current-user-slice";
import userChildSlice from "./user-children/user-children-slice";
import userDoctorSlice from "./user-doctor/user-doctor-slice";
import userDriverSlice from "./user-drivers/user-drivers-slice";
import userFormsSlice from "./user-forms/user-forms-slice";
import userGuardianSlice from "./user-guardians/user-guardian-slice";
import registrationYearsSlice from "./registration-years/registration-years-slice";
import ChildrenDataSlice from "./children-data/children-data-slice";

const store = configureStore({
  reducer: {
    currentUser: currentUserSlice.reducer,
    adminUser: adminUserSlice.reducer,
    userChild: userChildSlice.reducer,
    userGuardian: userGuardianSlice.reducer,
    userDriver: userDriverSlice.reducer,
    userDoctor: userDoctorSlice.reducer,
    userForms: userFormsSlice.reducer,
    registrationYears: registrationYearsSlice.reducer,
    childrenData: ChildrenDataSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
