export const config = {
  firebaseConfig: {
    apiKey: "AIzaSyAVNZbK88gJwB2kZAG0qP-6OW2lSE99L-0",
    authDomain: "bambini-production-30bc0.firebaseapp.com",
    projectId: "bambini-production-30bc0",
    storageBucket: "bambini-production-30bc0.appspot.com",
    messagingSenderId: "773603888847",
    appId: "1:773603888847:web:201267c30cbe4a2d9571ba",
    measurementId: "G-HHJWXGRGH3",
  },
};
