import React, { FC } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Avatar from '@mui/material/Avatar';
import { DocumentationDTO } from '../../models/documentation/documentation';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { ListItemButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import TopicIcon from '@mui/icons-material/Topic';
import { WidgetCard } from '../shared/Cards/WidgetCard';

interface DocumentationListProps {
	documents: DocumentationDTO[];
}

export const DocumentationList: FC<DocumentationListProps> = ({
	documents,
}) => {
	return (
		<WidgetCard
			title={'Dokumentasie'}
			icon={<TopicIcon />}
			primaryColor={'#68ACE0'}
			secondaryColor={'#c8e4fa'}
			bgcolor={'#fefffe'}
			content={
				<List sx={{ width: '100%', bgcolor: '#fefffe' }}>
					{documents.map((document, index) => {
						return (
							<React.Fragment key={index}>
								<ListItemButton onClick={() => window.open(document.location)}>
									<ListItemAvatar>
										<Avatar
											variant="rounded"
											sx={{
												color: '#68ACE0',
												bgcolor: '#f4f2f8',
											}}
										>
											<AttachFileIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={document.name}
										secondary={document.description}
									/>
									<ArrowForwardIosIcon />
								</ListItemButton>
							</React.Fragment>
						);
					})}
				</List>
			}
		/>
	);
};
