import {
  getDocs,
  query,
  Timestamp,
  doc,
  getFirestore,
  setDoc,
  where,
} from "firebase/firestore";
import { collection } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RegisterYearsDTO } from "../../models/register-years/register-years";
import { ChildDataPointDTO } from "../../models/child_data_point/child_data_point";
import { GuardianDataPointDTO } from "../../models/child_data_point/guardian_data_point";
import { DoctorDataPointDTO } from "../../models/child_data_point/doctor_data_point";
import { getAuth } from "firebase/auth";
import { UserChildrenDTO } from "../../models/children/user-children";
import { UserDoctorDTO } from "../../models/doctor/user-doctor";
import { UserGuardianDTO } from "../../models/guardian/user-guardian";

export const getRegistrationYears = createAsyncThunk(
  "/registrationYears/getRegistrationYears",
  async () => {
    const registerYears: RegisterYearsDTO[] = [];

    const ref = collection(getFirestore(), "registration_year");
    const q = query(ref);
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if (doc.data().active) {
        registerYears.push({
          docId: doc.id,
          active: doc.data().active,
          deposit: doc.data().deposit,
          registerFee: doc.data().registerFee,
          schoolFee: doc.data().schoolFee,
          afterSchool: doc.data().afterSchool,
        });
      }
    });

    return registerYears;
  }
);

const getChildren = async (uid: string): Promise<UserChildrenDTO[]> => {
  const ref = collection(getFirestore(), "user_children");
  const q = query(ref, where("currentUser.uid", "==", uid));
  const querySnapshot = await getDocs(q);

  const children = querySnapshot.docs.map((doc) => {
    return { ...doc.data().child } as UserChildrenDTO;
  });

  return children;
};

const getGuardians = async (uid: string): Promise<GuardianDataPointDTO[]> => {
  const ref = collection(getFirestore(), "user_guardians");
  const q = query(ref, where("currentUser.uid", "==", uid));
  return await getDocs(q).then((querySnapshot) => {
    return querySnapshot.docs.map((doc) => {
      const guardian = { ...doc.data().guardian } as UserGuardianDTO;

      return {
        name: guardian.name,
        surname: guardian.surname,
        preferredName: guardian.preferredName,
        cellNumber: guardian.cellNumber,
        email: guardian.email,
      } as GuardianDataPointDTO;
    });
  });
};

const getDoctors = async (uid: string): Promise<DoctorDataPointDTO[]> => {
  const ref = collection(getFirestore(), "user_doctor");
  const q = query(ref, where("currentUser.uid", "==", uid));
  return await getDocs(q).then((querySnapshot) => {
    return querySnapshot.docs.map((doc) => {
      const doctor = { ...doc.data().doctor } as UserDoctorDTO;

      return {
        name: doctor.name,
        surname: doctor.surname,
        cellNumber: doctor.cellNumber,
      } as DoctorDataPointDTO;
    });
  });
};

export const createChildrenData = createAsyncThunk(
  "/registrationYears/createChildrenData",
  async (year: string) => {
    const uid = await getAuth().currentUser?.uid;

    if (!uid) {
      return;
    }

    const [children, guardians, doctors] = await Promise.all([
      getChildren(uid),
      getGuardians(uid),
      getDoctors(uid),
    ]);

    for (const child of children) {
      const newDocRef = doc(
        getFirestore(),
        "registration_years_data",
        year + "-" + child.docId
      );
      setDoc(newDocRef, {
        name: child.name,
        surname: child.surname,
        preferredName: child.preferredName,
        gender: child.gender,
        dateOfBirth: child.dateOfBirth,
        guardians,
        doctors,
        registrationYear: year,
        lastUpdated: Timestamp.now(),
        created: Timestamp.now(),
      } as ChildDataPointDTO).catch((error) => {
        console.log("Failed to set", error);
      });
    }
  }
);
