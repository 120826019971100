import React, { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { GlobalStyles } from '@mui/material';

interface FormContainerProps {
	title?: string;
	body: JSX.Element;
}

const FormContainer: FC<FormContainerProps> = ({ body }) => {
	return (
		<Container>
			<CssBaseline />
			<GlobalStyles
				styles={{
					body: { backgroundColor: '#A5D5F9' },
				}}
			/>
			<Box
				component="main"
				sx={{
					bgcolor: '#FAF9F6',
					px: 2,
					pb: 2,
					borderRadius: 3,
					boxShadow: 5,
					my: 2,
					mt: 14,
				}}
			>
				{body}
			</Box>
		</Container>
	);
};

export default FormContainer;
