import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { UserFormsDTO } from "../../../models/user-forms/user-forms";
import { UserFormsChildDataDTO } from "../../../models/user-forms/user-forms-child-data";

export const reRegisterChildren = async (
  year: string,
  reRegisterChildrenList: UserFormsChildDataDTO[]
): Promise<UserFormsChildDataDTO[] | null> => {
  const currentUser = await getAuth().currentUser;

  if (currentUser == null) return null;

  const docRef = doc(getFirestore(), "user_forms", currentUser.uid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return null;
  }

  const userForm = docSnap.data() as UserFormsDTO;
  const currentChildren = userForm.children;

  const newChildren: UserFormsChildDataDTO[] = currentChildren.map(
    (currentChild) => {
      if (
        reRegisterChildrenList.find(
          (child: UserFormsChildDataDTO) => child.docId === currentChild.docId
        )
      ) {
        const currentRegistrationYears: string[] =
          currentChild.registrationYears === undefined
            ? []
            : [...currentChild.registrationYears];

        return {
          ...currentChild,
          archived: false,
          registrationYears: [...currentRegistrationYears, year],
        };
      }
      return currentChild;
    }
  );

  await updateDoc(docRef, {
    children: newChildren,
  });

  return newChildren;
};
