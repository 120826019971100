/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";
import { DataStatus } from "../../models/data/data-status";
import { deregisterChild } from "./admin-user-actions";

interface AdminUserStoreDTO {
  data: {
    admin: boolean;
  };
  dataStatus: DataStatus;
}

const initial: AdminUserStoreDTO = {
  data: {
    admin: false,
  },
  dataStatus: DataStatus.INITIAL,
};

const adminUserSlice = createSlice({
  name: "admin-user",
  initialState: initial,
  reducers: {
    setAdminUser(state: any, actions: any) {
      state.data = actions.payload;
    },
    logout(state: any) {
      state.data.admin = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deregisterChild.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(deregisterChild.fulfilled, (state, actions) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(deregisterChild.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const adminUserActions = adminUserSlice.actions;

export default adminUserSlice;
