import { doc, getDocs, getFirestore } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChildDataPointDTO } from "../../models/child_data_point/child_data_point";

export const deregisterChild = createAsyncThunk(
  "/admin/deregisterChild",
  async () => {
    // const collectionRef = doc(getFirestore(), "user_for", uid);
    // const querySnapshot = await getDocs(collectionRef);
    // return children;
  }
);
