import { FileUploadButtonDTO } from '../../../models/forms/file-upload-button';
import { InputDateFieldDTO } from '../../../models/forms/form-date-field';
import { useState } from 'react';

export const GenerateInputTextField = (
	name: string,
	initialValue: string,
	required: boolean,
	type?: string,
	lengthEqual?: number | undefined,
	helperText?: string,
): InputTextFieldDTO => {
	const [value, setValue] = useState<string>(initialValue);
	const [error, setError] = useState(required);
	const typeValue = type ? type : 'text';
	const baseHelperText = helperText ? helperText : '';

	return {
		name,
		required,
		type: typeValue,
		baseHelperText,
		lengthEqual,
		error,
		setError,
		value,
		setValue,
	};
};

export const GenerateInputMultiLineTextField = (
	name: string,
	initialValue: string,
	required: boolean,
	type?: string,
	lengthEqual?: number | undefined,
): InputTextFieldDTO => {
	const [value, setValue] = useState<string>(initialValue);
	const [error, setError] = useState(required);
	const typeValue = type ? type : 'text';

	return {
		name,
		required,
		type: typeValue,
		lengthEqual,
		error,
		setError,
		value,
		setValue,
	};
};

export const GenerateInputNumberField = (
	name: string,
	initialValue: string,
	required: boolean,
	lengthEqual?: number | undefined,
): InputNumberFieldDTO => {
	const [value, setValue] = useState<string>(initialValue);
	const [error, setError] = useState(required);

	return {
		name,
		required,
		lengthEqual,
		error,
		setError,
		value,
		setValue,
	};
};

export const GenerateInputDateField = (
	name: string,
	initialValue: Date | null,
	required: boolean,
): InputDateFieldDTO => {
	const [value, setValue] = useState<Date | null>(initialValue);
	const [error, setError] = useState(true);

	return {
		name,
		required,
		error,
		setError,
		value,
		setValue,
	};
};

export const GenerateInputSelectField = (
	name: string,
	initialValue: string,
	list: string[],
	required: boolean,
): InputSelectFieldDTO => {
	const [value, setValue] = useState<string | null>(initialValue);
	const [error, setError] = useState(true);

	return {
		name,
		list,
		required,
		error,
		setError,
		value,
		setValue,
	};
};

export const GenerateFileUploadButton = (
	name: string,
	initialValue: string,
	docId: string,
	required: boolean,
): FileUploadButtonDTO => {
	const [file, setFile] = useState<string>(initialValue);
	const [error, setError] = useState(true);

	return {
		docId,
		name,
		required,
		error,
		setError,
		file,
		setFile,
	};
};
