import {
  deleteDoc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { UserFormsDTO } from "../../models/user-forms/user-forms";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const getUserForms = createAsyncThunk(
  "/userForms/getUserForms",
  async () => {
    const currentUser = await getAuth().currentUser;

    if (currentUser) {
      const document = await getDoc(
        doc(getFirestore(), "user_forms", currentUser.uid)
      );

      if (document.exists()) {
        return document.data() as UserFormsDTO;
      }

      const userForm: UserFormsDTO = {
        children: [],
        guardians: [],
        drivers: [],
        doctor: [],
        singleParent: null,
        noDrivers: null,
        formsComplete: false,
      };

      if (!document.exists()) {
        await setDoc(doc(getFirestore(), "user_forms", currentUser.uid), {
          ...userForm,
        });
      }
    }
    return null;
  }
);

export const updateSingleParentState = createAsyncThunk(
  "/userForms/updateSingleParentState",
  async (state: boolean) => {
    const currentUser = await getAuth().currentUser;

    if (currentUser) {
      await updateDoc(doc(getFirestore(), "user_forms", currentUser.uid), {
        singleParent: state,
      });
    }

    return state;
  }
);

export const updateNoDriverState = createAsyncThunk(
  "/userForms/updateNoDriverState",
  async (state: boolean) => {
    const currentUser = await getAuth().currentUser;

    if (currentUser) {
      await updateDoc(doc(getFirestore(), "user_forms", currentUser.uid), {
        noDrivers: state,
      });
    }

    return state;
  }
);

export const removeUserForms = createAsyncThunk(
  "/userForms/removeUserForms",
  async (docId: string) => {
    await deleteDoc(doc(getFirestore(), "user_forms", docId));
  }
);
