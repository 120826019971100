import {
  Timestamp,
  deleteDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { collection, doc } from "firebase/firestore";

import { UserGuardianDTO } from "../../models/guardian/user-guardian";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";

export const getUserGuardians = createAsyncThunk(
  "/userGuardians/getUserGuardians",
  async () => {
    const currentUser = await getAuth().currentUser;

    const userGuardians: UserGuardianDTO[] = [];

    if (currentUser) {
      const ref = collection(getFirestore(), "user_guardians");
      const q = query(ref, where("currentUser.uid", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        userGuardians.push({
          docId: doc.id,
          ...doc.data().guardian,
        });
      });
    }

    return userGuardians;
  }
);

export const addUserGuardian = createAsyncThunk(
  "/userGuardians/addUserGuardians",
  async (guardian: UserGuardianDTO) => {
    const currentUser = await getAuth().currentUser;

    await setDoc(doc(getFirestore(), "user_guardians", guardian.docId), {
      guardian,
      currentUser: {
        uid: currentUser?.uid,
        email: currentUser?.email,
      },
      created: Timestamp.now(),
      lastUpdated: Timestamp.now(),
    });
  }
);

export const removeUserGuardian = createAsyncThunk(
  "/userGuardians/removeUserGuardian",
  async (docId: string) => {
    await deleteDoc(doc(getFirestore(), "user_guardians", docId));
  }
);
