import { Box, Grid } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../hooks/hooks';

import { DataStatus } from '../../models/data/data-status';
import LoadingPage from '../../components/shared/Loaders/LoadingPage';
import { SelectChildrenRegisterWidget } from '../../components/Widgets/SelectChildrenRegisterWidget/SelectChildrenRegisterWidget';

export const RegisterAgainPage = () => {
	const data = useAppSelector((store) => store.userForms.data);
	const userFormsStatus = useAppSelector((store) => store.userForms.dataStatus);

	return (
		<Box sx={{ mt: 12, width: '100vw' }}>
			{userFormsStatus === DataStatus.LOADING || data === null ? (
				<LoadingPage />
			) : (
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					sx={{ px: 2, pb: 2 }}
				>
					<Grid item xs={12}>
						<SelectChildrenRegisterWidget userForm={data} />
					</Grid>
				</Grid>
			)}
		</Box>
	);
};
