import { Button, Divider, Grid, Typography } from '@mui/material';
import React, { FC, useState } from 'react';

import ErrorsModal from '../../Modals/ErrorsModal';
import FileUploadButton from '../../shared/FileUpload/FileUploadButton';
import { FileUploadButtonDTO } from '../../../models/forms/file-upload-button';
import { FormContentDTO } from '../../../models/forms/form-content';
import { FormErrorDTO } from '../../../models/form-error/form-error';
import { FormFieldTypes } from '../../../models/forms/form-field-types';
import InputDateField from '../../shared/InputFields/InputDateField';
import { InputDateFieldDTO } from '../../../models/forms/form-date-field';
import InputMultiLineTextField from '../../shared/InputFields/InputMultiLineTextField';
import InputNumberField from '../../shared/InputFields/InputNumberField';
import InputSelectField from '../../shared/InputFields/InputSelectField';
import InputTextField from '../../shared/InputFields/InputTextField';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/hooks';

interface FormBuilderProps {
	form: FormContentDTO;
	submit: (validForm: boolean) => void;
	exists?: boolean;
}

const FormBuilder: FC<FormBuilderProps> = ({ form, submit, exists }) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [errors, setErrors] = useState<FormErrorDTO[]>([]);

	const formsComplete = useAppSelector(
		(store) => store.userForms.data?.formsComplete,
	);

	const formContent = form.formContent;

	const validateData = () => {
		const errorList: FormErrorDTO[] = [];

		form.formContent.forEach((field) => {
			if (field.formFieldData.error) {
				errorList.push({
					name: field.formFieldData.name,
				});
			}
		});
		setErrors(errorList);

		if (errorList.length === 0) {
			submit(true);

			if (!!exists || !!formsComplete) {
				navigate('/bestuur-familie');
			} else {
				navigate('/vorm');
			}
		} else {
			setOpen(true);
		}
	};

	return (
		<React.Fragment>
			<ErrorsModal errors={errors} open={open} setOpen={setOpen} />
			<Grid container spacing={2}>
				{formContent.map((field, index) => {
					switch (field.formFieldType) {
						case FormFieldTypes.INPUT_TEXT_FIELD: {
							return (
								<Grid key={index} item xs={12} md={6}>
									<InputTextField
										inputTextField={field.formFieldData as InputTextFieldDTO}
									/>
								</Grid>
							);
						}
						case FormFieldTypes.INPUT_MULTI_LINE_TEXT_FIELD: {
							return (
								<Grid key={index} item xs={12}>
									<InputMultiLineTextField
										inputTextField={field.formFieldData as InputTextFieldDTO}
									/>
								</Grid>
							);
						}
						case FormFieldTypes.INPUT_SELECT_FIELD: {
							return (
								<Grid key={index} item xs={12} md={6}>
									<InputSelectField
										inputSelectField={
											field.formFieldData as InputSelectFieldDTO
										}
									/>
								</Grid>
							);
						}
						case FormFieldTypes.INPUT_NUMBER_FIELD: {
							return (
								<Grid key={index} item xs={12} md={6}>
									<InputNumberField
										inputNumberField={
											field.formFieldData as InputNumberFieldDTO
										}
									/>
								</Grid>
							);
						}
						case FormFieldTypes.INPUT_DATE_FIELD: {
							return (
								<Grid key={index} item xs={12} md={6}>
									<InputDateField
										inputDateField={field.formFieldData as InputDateFieldDTO}
									/>
								</Grid>
							);
						}
						case FormFieldTypes.FILE_UPLOAD_BUTTON: {
							return (
								<Grid key={index} item xs={12} md={6}>
									<FileUploadButton
										fileUploadButton={
											field.formFieldData as FileUploadButtonDTO
										}
									/>
								</Grid>
							);
						}
						case FormFieldTypes.FORM_SECTION_TITLE: {
							return (
								<Grid key={index} item xs={12}>
									<Typography
										variant="overline"
										display="block"
										sx={{ fontWeight: 700, fontSize: 18, color: '#777' }}
										gutterBottom
									>
										{field.formFieldData as string}
									</Typography>
								</Grid>
							);
						}
						default: {
							return <React.Fragment></React.Fragment>;
						}
					}
				})}
				<Divider sx={{ width: '90%', mt: 2, mx: 'auto' }} />
			</Grid>
			<Button
				variant="contained"
				sx={{ mt: 3, mx: 1 }}
				onClick={() => validateData()}
			>
				{!!exists ? 'Stuur' : 'Volgende Vorm'}
			</Button>
		</React.Fragment>
	);
};

export default FormBuilder;
