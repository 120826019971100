import React, { useEffect, useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { useNavigate, useParams } from 'react-router-dom';

import { GuardianFormGenerator } from './GuardianFormGenerator';
import LoadingPage from '../../shared/Loaders/LoadingPage';
import { UserGuardianDTO } from '../../../models/guardian/user-guardian';
import { doc } from 'firebase/firestore';

const GuardianForm = () => {
	const navigate = useNavigate();
	const { docId } = useParams();

	const guardiansRef = doc(useFirestore(), 'user_guardians', docId as string);

	const { status, data } = useFirestoreDocData(guardiansRef);

	const [form, setForm] = useState<JSX.Element>(<LoadingPage />);

	useEffect(() => {
		if (docId === 'nuwe-ouer') {
			setForm(<GuardianFormGenerator currentGuardian={null} />);
		} else if (data && status === 'success') {
			setForm(
				<GuardianFormGenerator
					currentGuardian={data.guardian as UserGuardianDTO}
				/>,
			);
		} else if (!data && status === 'success') {
			navigate('/404');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, docId]);

	return form;
};

export default GuardianForm;
