import { Alert, Avatar, Box, Button, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
	getDownloadURL,
	getMetadata,
	getStorage,
	ref,
	uploadBytes,
} from 'firebase/storage';

import { FileUploadButtonDTO } from '../../../models/forms/file-upload-button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useAppSelector } from '../../../hooks/hooks';

interface FileUploadButtonProps {
	fileUploadButton: FileUploadButtonDTO;
}

const FileUploadButton: FC<FileUploadButtonProps> = ({ fileUploadButton }) => {
	const docId = fileUploadButton.docId;
	const name = fileUploadButton.name;
	const file = fileUploadButton.file;
	const setFile = fileUploadButton.setFile;
	const setError = fileUploadButton.setError;

	const uid = useAppSelector((store) => store.currentUser.data.uid);
	const [initial, setInitial] = useState(true);
	const [loading, setLoading] = useState(false);
	const [errorUpload, setErrorUpload] = useState(false);
	const [fileRef, setFileRef] = useState('');
	const [fileType, setFileType] = useState<string | undefined>('');

	const storage = getStorage();
	const link = 'documents/' + uid + '/' + name.replace(/\s/g, '') + '/' + docId;
	const storageRef = ref(storage, link);

	useEffect(() => {
		if (file !== '') {
			getDownloadURL(storageRef)
				.then((url) => {
					getMetadata(storageRef).then((metadata) => {
						setFileType(metadata.contentType);
					});
					setFileRef(url);
					setError(false);
				})
				.catch((error) => {
					setFile('');
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadFile = (newFile: File) => {
		setLoading(true);

		if (newFile == null) {
			setError(true);
			return;
		}

		uploadBytes(storageRef, newFile)
			.then((snapshot) => {
				getDownloadURL(storageRef)
					.then((url) => {
						setFileRef(url);
						getMetadata(storageRef).then((metadata) => {
							setFileType(metadata.contentType);
						});
					})
					.catch((error) => { });
				setFile(snapshot.ref.fullPath);
				setLoading(false);
				setErrorUpload(false);
				setError(false);
			})
			.catch((e) => {
				setLoading(false);
				setErrorUpload(true);
				setError(true);
			});

		if (initial) {
			setInitial(false);
		}
	};

	return (
		<Box sx={{ mx: 1 }}>
			<Typography variant="h6" gutterBottom component="div">
				{name}
			</Typography>
			<React.Fragment>
				{file !== '' ? (
					<React.Fragment>
						<Alert sx={{ m: 1 }} severity="success">
							{name} is suksesvol opgelaai
						</Alert>
						{fileType && fileType.includes('image') ? (
							<Avatar
								sx={{ my: 1, mx: 'auto', width: 200, height: 200 }}
								variant="rounded"
								alt="Uploaded File"
								src={fileRef}
							/>
						) : (
							<></>
						)}
					</React.Fragment>
				) : (
					<React.Fragment></React.Fragment>
				)}
			</React.Fragment>
			{errorUpload ? (
				<Alert sx={{ my: 1 }} severity="error">
					{name} moet 'n foto wees wat 5MB of klein is
				</Alert>
			) : (
				<></>
			)}
			{loading ? (
				<LoadingButton
					loading
					fullWidth
					loadingPosition="start"
					startIcon={<SaveIcon />}
					variant="outlined"
				>
					Besig om op te laai
				</LoadingButton>
			) : (
				<Button
					variant="contained"
					fullWidth
					component="label"
					startIcon={<FileUploadIcon />}
				>
					Laai op
					<input
						type="file"
						hidden
						accept="application/pdf,image/*"
						onChange={(e) => {
							if (e.target.files) {
								uploadFile(e.target.files[0]);
							}
						}}
					/>
				</Button>
			)}
		</Box>
	);
};

export default FileUploadButton;
