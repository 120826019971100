import {
  addUserChild,
  getUserChild,
  removeUserChild,
  updateUserChild,
} from "./user-children-actions";

import { DataStatus } from "../../models/data/data-status";
import { UserChildrenDTO } from "../../models/children/user-children";
/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

interface ChildStoreDTO {
  data: UserChildrenDTO[];
  dataStatus: DataStatus;
}

const initial: ChildStoreDTO = {
  data: [],
  dataStatus: DataStatus.INITIAL,
};

const userChildSlice = createSlice({
  name: "user-child",
  initialState: initial,
  reducers: {
    logout(state: any) {
      state.data = [];
      state.dataStatus = DataStatus.INITIAL;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserChild.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(getUserChild.fulfilled, (state, actions) => {
      state.data = actions.payload;
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(getUserChild.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(addUserChild.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(addUserChild.fulfilled, (state, actions) => {
      state.data.push(actions.payload);
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(addUserChild.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(updateUserChild.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(updateUserChild.fulfilled, (state, actions) => {
      state.data.map((child) => {
        if (child.docId === actions.payload.docId) {
          return actions.payload;
        }
        return child;
      });
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(updateUserChild.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });

    builder.addCase(removeUserChild.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(removeUserChild.fulfilled, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(removeUserChild.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const userChildActions = userChildSlice.actions;

export default userChildSlice;
