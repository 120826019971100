import {
  createChildrenData,
  getRegistrationYears,
} from "./registration-years-actions";

import { DataStatus } from "../../models/data/data-status";
import { createSlice } from "@reduxjs/toolkit";
import { RegisterYearsDTO } from "../../models/register-years/register-years";

interface RegisterYearsStoreDTO {
  data: RegisterYearsDTO[];
  dataStatus: DataStatus;
}

const initial: RegisterYearsStoreDTO = {
  data: [],
  dataStatus: DataStatus.INITIAL,
};

const registrationYearsSlice = createSlice({
  name: "registration-years",
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRegistrationYears.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(getRegistrationYears.fulfilled, (state, actions) => {
      state.data = actions.payload;
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(getRegistrationYears.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(createChildrenData.pending, (state) => {
      state.dataStatus = DataStatus.LOADING;
    });
    builder.addCase(createChildrenData.fulfilled, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
    builder.addCase(createChildrenData.rejected, (state) => {
      state.dataStatus = DataStatus.COMPLETE;
    });
  },
});

export const registrationYearsActions = registrationYearsSlice.actions;

export default registrationYearsSlice;
