import { getDocs, getFirestore } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChildDataPointDTO } from "../../models/child_data_point/child_data_point";
import { ChildrenDataDTO, RegisterCountDataDTO } from "../../models/child_data_point/children_data";

const computerChildrenStats = (
  children: ChildDataPointDTO[]
): RegisterCountDataDTO[] => {
  const registrationYearsStats: RegisterCountDataDTO[] = [];

  children.forEach((child) => {
    const registrationYearStat = registrationYearsStats.find(
      (stat) => stat.year === child.registrationYear
    );
    if (!!registrationYearStat) {
      registrationYearStat.count++;
    } else {
      registrationYearsStats.push({ year: child.registrationYear, count: 1 });
    }
  });

  return registrationYearsStats;
};

export const getChildrenData = createAsyncThunk(
  "/children_data/getChildrenData",
  async () : Promise<ChildrenDataDTO> => {
    const collectionRef = collection(getFirestore(), "registration_years_data");
    const querySnapshot = await getDocs(collectionRef);

    const children: ChildDataPointDTO[] = querySnapshot.docs.map((document) => {
      return document.data() as ChildDataPointDTO;
    });

    const registerCountData = computerChildrenStats(children);

    return {
      children,
      registerCountData,
    };
  }
);
